import React, { useState, useEffect } from "react";
import logo from "./logo.png";
import profile from "./user.png";
import { Link } from "react-router-dom";
import Dropdown from "./dropdown";
import { useTranslation } from "react-i18next";
import arror from "./arror.png";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import dash from "../Sidebar/images/dash.png";
import fundw from "../Sidebar/images/fundw.png";
import withdraw from "../Sidebar/images/withdraw.png";
import referrals from "../Sidebar/images/pension.png";
import logout from "../Sidebar/images/logout.png";
import settings from "../Sidebar/images/settings.png";
import pension from "../Sidebar/images/pension.png";
import help from "../Sidebar/images/help.png";
import close from "../Sidebar/images/close.png";
import Modal from "react-bootstrap/Modal";

import "./header.css";
import { DollarSign } from "lucide-react";

function UserHeader({ title, fke }) {
	const [users, setUser] = useState(null);
	const [userplan, setUserPlan] = useState(null);
	const [lang, setLang] = useState(localStorage.getItem("lang"));
	const { t, i18n } = useTranslation();
	const [show, setShow] = useState(false); // Added state for modal
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const navigate = useNavigate();
	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem("SeamR_user")));
		if (localStorage.getItem("SeamR_plan")) {
			setUserPlan(JSON.parse(localStorage.getItem("SeamR_plan")));
		}
	}, []);

	const optionsState = (e) => {
		const { name, value } = e.target;
		if (name === "language") {
			setLang(value);
			i18n.changeLanguage(value);
			localStorage.setItem("lang", value);
		}
	};
	function logoutbtn() {
		localStorage.removeItem("SeamR_user");
		window.location.reload();
	}
	// Extract user information
	const user = users ? users.user : null;
	const userName = user ? user.name : "User";
	const userInitials = userName
		.split(" ")
		.map((word) => word.charAt(0))
		.join("")
		.toUpperCase();

	return (
		<>
			<div className="user_head">
				<div className="window_nav">
					{[false].map((expand) => (
						<Navbar key={expand} expand={expand}>
							<Navbar.Toggle
								aria-controls={`offcanvasNavbar-expand-${expand}`}
							/>
							<Navbar.Offcanvas
								id={`offcanvasNavbar-expand-${expand}`}
								aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
								placement="start"
							>
								<Offcanvas.Header closeButton>
								
								</Offcanvas.Header>
								<Offcanvas.Body>
									<Nav className="justify-content-end flex-grow-1 pe-3">
										<ul className="navlist  navlists_kk">
											<li>
												<Link className="menu-item" to="/app">
													<img src={dash} alt="Dashboard" />
													{t("Dashboard")}
												</Link>
											</li>
											<li>
												<Link className="menu-item" to="/app/loans">
													<img src={pension} alt="Pension" />
													Loans
												</Link>
											</li>
											<li>
												<Link className="menu-item" to="/app/withdraw">
													<img src={withdraw} alt="Withdrawals" />
													Withdrawals
												</Link>
											</li>
											<li>
												<Link className="menu-item" to="/app/payments">
													<img src={fundw} alt="Payments" />
													Payments
												</Link>
											</li>
											<li>
												<Link className="menu-item" to="/app/support">
													<img src={help} alt="Support" />
													Support
												</Link>
											</li>
											<li>
												<Link className="menu-item" to="/app/settings">
													<img src={settings} alt="Settings" />
													Settings
												</Link>
											</li>
											<li>
												<Link className="menu-item sdm" to="/app/referrals">
													<DollarSign size={20} />
													Refer & Get Cash Reward
												</Link>
											</li>
											<li>
												<Link className="menu-item" onClick={handleShow}>
													<img src={logout} alt="Logout" />
													Logout
												</Link>

												<Modal show={show} onHide={handleClose} centered>
													<div className="mo_head">
														<Modal.Title>Logout</Modal.Title>
														<span onClick={handleClose}>
															<img src={close} alt="Close" />
														</span>
													</div>
													<Modal.Body>
														Are you sure you want to logout from this account?
													</Modal.Body>
													<div className="mo_foot">
														<button className="chek" onClick={handleClose}>
															Cancel
														</button>
														<Button variant="danger" onClick={logoutbtn}>
															Logout
														</Button>
													</div>
												</Modal>
											</li>
										</ul>
									</Nav>
								</Offcanvas.Body>
							</Navbar.Offcanvas>
						</Navbar>
					))}
				</div>

				<div className="user_left">
					<Link className="logo_class" exact="true" to="/app">
						<img src={logo} alt="logo" className="user_logo" />
					</Link>
					{fke ? (
						<img
							src={arror}
							alt="logo"
							width={"32px"}
							style={{
								marginRight: "10px",
								position: "relative",
								top: "-2px",
								cursor: "pointer",
							}}
							onClick={() => navigate("/app/withdraw")}
						/>
					) : (
						""
					)}

					<span className="header_head">{title}</span>
				</div>
				<span className="user_right">
					<span className="lngtxt">Language:</span>
					<span className="lng">
						<select name="language" value={lang} onChange={optionsState}>
							<option value={"en"}>English</option>
							<option value={"de"}>Germany</option>
							<option value={"it"}>Italian</option>
							<option value={"es"}>Spanish</option>
						</select>
					</span>

					<span className="user_profile">
						{user ? (
							// Show the initials in a circular design
							<div className="user_initials">{userInitials}</div>
						) : (
							// Show default profile image when no user
							<img src={profile} alt="user" />
						)}
					</span>

					{/* <span className="dropdown">
						<Dropdown />
					</span> */}
				</span>
			</div>
		</>
	);
}

export default UserHeader;
