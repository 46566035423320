import React, { useEffect } from "react";
import "../css/miniFoot.css";
import play from "./play.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function MiniFoot() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container">
			<div className="miniFoot">
				<h1 data-aos="fade-right">Is Seamridge right for you?</h1>
				<a className="main-btn mnf-btn ml-30" href="/check-rate" data-aos="fade-left">
					Check your rate
				</a>
				
				<p data-aos="fade-up"><span>Checking your rate is free and won’t impact your credit score*</span></p>
			</div>
		</div>
	);
}
export default MiniFoot;
