import React from "react";
import Moment from "moment";

const UserTrans = ({ created_at, price, accept, description, slug }) => {
	const formattedDate = Moment(created_at).format("DD MMM, YYYY");
	const formattedAmount = Number(price).toLocaleString("en-US");

	let transactionStatus =
		{
			0: "Pending",
			1: "Approved",
		}[accept] || "Rejected";

	// If the description is "origination fee", override the status to "Paid"
	if (description === "Origination fee" || description === "origination fee") {
		transactionStatus = "Paid";
	}
	return (
		<div className="UserTrans">
			<div className="col-date">{formattedDate}</div>
			<div className="col-loan-type">{slug}</div> {/* Loan type column added */}
			<div className="col-amount">$ {formattedAmount}</div>
			<div className="col-status">
				<span className="sta">
					<span
						className={
							transactionStatus == "Approved" || transactionStatus == "Paid"
								? "circle"
								: transactionStatus == "Pending"
								? "yellow"
								: "red"
						}
					></span>
					{transactionStatus}
				</span>
			</div>
			<div className="col-description  mobile_off">{description}</div>
		</div>
	);
};

export default UserTrans;
