import React, { useEffect } from "react";
import s1 from "./images/s1.png";
import s2 from "./images/s2.png";
import s3 from "./images/s3.png";
import s4 from "./images/s4.png";
import s5 from "./images/s5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./dash.css";
function DashSlide() {
	const settings = {
		centerMode: true,
		centerPadding: "20px", // Adds padding between cards
		draggable: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 5,
		dots: true,
	};

	return (
		<div className="">
			<div className="img_cont">
				<Slider {...settings}>
					<img src={s1} width={"100%"} alt="pic" />
					<img src={s2} alt="pic" />
					<img src={s3} alt="pic" />
					<img src={s4} alt="pic" />
					<img src={s5} alt="pic" />
				</Slider>
			</div>
		</div>
	);
}

export default DashSlide;
