import React, { useEffect, useState } from "react";
import "../css/about.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function AboutTeam() {
	const [show, setshow] = useState(false);
	const handleChange = (e) => {
		setshow(true);
		setTimeout(() => {
			setshow(false);
		}, 3000);
	};
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container">
			<div className="miniFoot ppp">
				<h3 data-aos=" fade-right">
					Ready to take the next step? Apply now <br /> in 5 minutes!
				</h3>
				<br />
				<a
					className="main-btn mnf-btn ml-30"
					href="/check-rate"
					data-aos="fade-left"
				>
					Check your rate
				</a>
			</div>
		</div>
	);
}

export default AboutTeam;
