import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/fund.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import close from "../../Sidebar/images/close.png";
import panel from "./img/panel.png";
import wif from "./img/wif.png";
import master from "./img/master.png";
import dg from "./img/dg.png";
import pay from "./img/pay.png";
import { useTranslation } from "react-i18next";
import WithdrawTable from "./WithdrawTable";

function UserWithdraw() {
	const { t } = useTranslation();
	const [wallet, setwallet] = useState("");
	const [pmethod, setpmethod] = useState("");
	const [amount, setamount] = useState("");
	const [formE, setformE] = useState(false);
	const [formNext, setformNext] = useState(false);
	const [token, settoken] = useState("");
	const [usersbal, setusersbal] = useState("");
	const [usersname, setusersname] = useState("");
	const [showEdit, setShowEdit] = useState(false);
	const navigate = useNavigate();

	// Load user data
	useEffect(() => {
		const storedUser = localStorage.getItem("SeamR_user");
		if (storedUser) {
			const parsedUser = JSON.parse(storedUser);
			settoken(parsedUser.token);
			setusersname(parsedUser.user.name);
		}
	}, []);

	const handleEditClose = () => setShowEdit(false);
	const handleEditShow = () => setShowEdit(true);

	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name === "wallet") setwallet(value);
		if (name === "method") setpmethod(value);
		if (name === "amount") setamount(value);
	};

	const handleMaxClick = () => {
		setamount(usersbal?.total || 0);
	};

	const handleNextStep = () => {
		if (!amount) {
			setformE(true);
			setTimeout(() => setformE(false), 2000);
			return;
		}
		setformNext(true);
	};

	const handlePaymentSelection = (method) => {
		navigate(`/${method}?amount=${amount}`);
	};
	const isFormValid = amount !== "";

	// Load user balance
	useEffect(() => {
		const storedUsers = localStorage.getItem("SeamR_users");
		if (storedUsers) setusersbal(JSON.parse(storedUsers));
	}, []);

	const total = usersbal?.total || 0;
	const limit = usersbal?.limit || 0;

	return (
		<>
			<div className="dashboard-container">
				<span className="secound_dash with_dash">
					<div className="sdash_cont">
						<p>Balance available</p>
						<h3>$ {total.toLocaleString()}</h3>
						<div className="dsh_body">
							<div className="dsh_b">
								<p>
									Credit limit <br /> <span>$-{limit.toLocaleString()}</span>
								</p>
							</div>
						</div>
					</div>
					<div className="ldashcont">
						<span className="dash_port">
							<img src={panel} alt="panel" className="ldash_panel" />
							<img src={wif} alt="wif" className="ldash_wif" />
						</span>
					</div>

					<div className="dash_btm wit_btm">
						<a className="dsh_btn" onClick={handleEditShow}>
							Withdraw
						</a>
						<span className="dash_btm_img">
							<img src={master} alt="master" className="ldash_master" />
						</span>
					</div>
				</span>
			</div>

			{/* Withdrawal Modal */}
			<Modal show={showEdit} onHide={handleEditClose} centered>
				{!formNext ? (
					<div>
						<div className="mo_head">
							<Modal.Title>Withdrawal amount </Modal.Title>
							<span onClick={handleEditClose}>
								<img src={close} alt="close" />
							</span>
						</div>

						<div className="withdrawal-container">
							<p>How much would you like to withdraw?</p>
							<div className="balance">Balance</div>
							<div className="balance-amount">${total.toLocaleString()}</div>

							<div className="input-container">
								<span className="currency">$</span>
								<input
									type="text"
									value={amount}
									onChange={(e) => setamount(e.target.value)}
								/>
								<button className="max-button" onClick={handleMaxClick}>
									Max
								</button>
							</div>
							{formE && <p className="error-message">Please enter an amount</p>}
							<div className="mo_foot_single">
								<button
									className={`mo_btn_single ${isFormValid ? "active" : ""}`}
									disabled={!isFormValid}
									onClick={handleNextStep}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="mo_head">
							<Modal.Title>Withdrawal amount </Modal.Title>
							<span onClick={handleEditClose}>
								<img src={close} alt="close" />
							</span>
						</div>

						<div className="withdrawal-container">
							<p>Withdraw your funds via:</p>
							<div className="payment-options">
								<button
									className={`payment-btn crypto-btn ${
										pmethod === "crypto" ? "selected" : ""
									}`}
									onClick={() => setpmethod("crypto")}
								>
									<img src={dg} alt="master" className="method_img" />
									Digital Currencies (Crypto)
								</button>
								<button
									className={`payment-btn paypal-btn ${
										pmethod === "paypal" ? "selected" : ""
									}`}
									onClick={() => setpmethod("paypal")}
								>
									<img src={pay} alt="master" className="method_img" />
									PayPal
								</button>
							</div>

							{formE && (
								<p className="error-message">Please select a payment method</p>
							)}

							<div className="mo_foot_single">
								<button
									className={`mo_btn_single ${pmethod ? "active" : ""}`}
									disabled={!pmethod}
									onClick={() => {
										if (!pmethod) {
											setformE(true);
											setTimeout(() => setformE(false), 2000);
											return;
										}
										navigate(`${pmethod}?amount=${amount}`);
									}}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				)}
			</Modal>

			<WithdrawTable />
		</>
	);
}

export default UserWithdraw;
