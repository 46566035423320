import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import empty from "../Fund/img/empty.png";
import UserTrans from "./UserTrans";
import per from "./per.png";
import ReactPaginate from "react-paginate";
function UserPension() {
	const [user, setUser] = useState([]);
	const [userId, setUserId] = useState("");
	const [pageNumber, setPageNumber] = useState(0);
	const [selectedSlug, setSelectedSlug] = useState("All");
	const navigate = useNavigate();
	const usersPerPage = 6;
	const pagesVisited = pageNumber * usersPerPage;

	// Fetch transactions
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				const storedUser = JSON.parse(localStorage.getItem("SeamR_user"));
				if (storedUser !== null) {
					setUserId(storedUser.user.id);
					setUser(result);
				}
			} catch (err) {
				console.error("Error fetching transactions:", err);
			}
		}
		fetchMyAPI();
	}, []);

	// Filter transactions based on user ID and valid conditions
	let filteredTransactions = user
		.filter(
			(item) =>
				(userId == item.user_id && item.name == "Loan") ||
				(userId == item.user_id &&
					item.name == "fund" &&
					item.description !== "Loan repayment")
		)
		.sort((a, b) => b.id - a.id); // Sort by latest ID

	// Calculate the total accepted amount for each loan type
	const totalAmounts = {
		Personal: filteredTransactions
			.filter((item) => item.slug === "Personal" && item.accept == 1)
			.reduce((sum, item) => sum + parseFloat(item.price || 0), 0),

		Business: filteredTransactions
			.filter((item) => item.slug === "Business" && item.accept == 1)
			.reduce((sum, item) => sum + parseFloat(item.price || 0), 0),

		Mortgage: filteredTransactions
			.filter((item) => item.slug === "Mortgage" && item.accept == 1)
			.reduce((sum, item) => sum + parseFloat(item.price || 0), 0),

		Car: filteredTransactions
			.filter((item) => item.slug === "Car" && item.accept == 1)
			.reduce((sum, item) => sum + parseFloat(item.price || 0), 0),
	};

	// Format numbers as currency, add a minus sign if the amount is negative
	const formatCurrency = (amount) => {
		const formattedAmount = amount.toLocaleString("en-US", {
			minimumFractionDigits: 2,
		});
		// Add "-" if the amount is less than 0
		return amount > 0
			? `-$${formattedAmount.replace("-", "")}`
			: `$${formattedAmount}`;
	};

	// Count transactions for each category
	const transactionCounts = {
		All: filteredTransactions.length,
		Personal: filteredTransactions.filter((item) => item.slug === "Personal")
			.length,
		Business: filteredTransactions.filter((item) => item.slug === "Business")
			.length,
		Mortgage: filteredTransactions.filter((item) => item.slug === "Mortgage")
			.length,
		Car: filteredTransactions.filter((item) => item.slug === "Car").length,
	};

	// Apply slug filter if selectedSlug is not "All"
	if (selectedSlug !== "All") {
		filteredTransactions = filteredTransactions.filter(
			(item) => item.slug === selectedSlug
		);
	}

	// Pagination logic
	const currentPosts = filteredTransactions.slice(
		pagesVisited,
		pagesVisited + usersPerPage
	);
	const pageCount = Math.ceil(filteredTransactions.length / usersPerPage);

	// Handle page change
	const changePage = ({ selected }) => setPageNumber(selected);

	// Handle filter button click
	const handleFilterClick = (slug) => {
		setSelectedSlug(slug);
		setPageNumber(0); // Reset to first page on filter change
	};

	return (
		<>
			<div className="top-pen">
				<div className="top-per-cont">
					<img src={per} alt="per" />
					<div>
						<h4>Personal Loans</h4>
						<h3>{formatCurrency(totalAmounts.Personal)}</h3>
						<p>(Limit: $1,000 - $100,000)</p>
					</div>
				</div>
				<div className="top-per-cont">
					<img src={per} alt="per" />
					<div>
						<h4>Business Loans</h4>
						<h3>{formatCurrency(totalAmounts.Business)}</h3>
						<p>(Limit: $100K - $100M)</p>
					</div>
				</div>
				<div className="top-per-cont">
					<img src={per} alt="per" />
					<div>
						<h4>Mortgage</h4>
						<h3>{formatCurrency(totalAmounts.Mortgage)}</h3>
						<p>(Limit: $10,000 - $4M)</p>
					</div>
				</div>
				<div className="top-per-cont">
					<img src={per} alt="per" />
					<div>
						<h4>Car Loans</h4>
						<h3>{formatCurrency(totalAmounts.Car)}</h3>
						<p>(Limit: $5,000 - $500K)</p>
					</div>
				</div>
			</div>
			<br />
			<button onClick={() => navigate("/loans")} className="main-btn ml-30">
				Apply for loan
			</button>
			<div id="history" className="history newhistory">
				{/* Filter Buttons with Counts */}
				<div className="filter-buttons">
					<button
						className={selectedSlug === "All" ? "active" : ""}
						onClick={() => handleFilterClick("All")}
					>
						All ({transactionCounts.All})
					</button>
					<button
						className={selectedSlug === "Personal" ? "active" : ""}
						onClick={() => handleFilterClick("Personal")}
					>
						Personal ({transactionCounts.Personal})
					</button>
					<button
						className={selectedSlug === "Business" ? "active" : ""}
						onClick={() => handleFilterClick("Business")}
					>
						Business ({transactionCounts.Business})
					</button>
					<button
						className={selectedSlug === "Mortgage" ? "active" : ""}
						onClick={() => handleFilterClick("Mortgage")}
					>
						Mortgage ({transactionCounts.Mortgage})
					</button>
					<button
						className={selectedSlug === "Car" ? "active" : ""}
						onClick={() => handleFilterClick("Car")}
					>
						Car ({transactionCounts.Car})
					</button>
				</div>

				{/* Display Transactions or Empty State */}
				{currentPosts.length === 0 ? (
					<center>
						<img className="hist_img" src={empty} alt="No transactions" />
					</center>
				) : (
					<div>
						<div className="transact-header">
							<div className="col-date">Date</div>
							<div className="col-loan-type">Loan Type</div>
							<div className="col-amount">Amount</div>
							<div className="col-status">Status</div>
							<div className="col-description mobile_off">Description</div>
						</div>

						<div className="H_line"></div>
						<div className="transactions">
							{currentPosts.map((item) => (
								<UserTrans
									key={item.id}
									id={item.id}
									description={item.description}
									name={item.name}
									accept={item.accept}
									slug={item.slug}
									dbdate={item.created_at}
									price={item.price.toLocaleString("en-US")}
								/>
							))}
						</div>
						<div className="histpg">
							<span className="foot_showing">
								Showing {pageNumber + 1} - {pageCount}{" "}
								<span>of {pageCount} items</span>
							</span>
							<span>
								<ReactPaginate
									previousLabel={"< Previous"}
									nextLabel={"Next >"}
									pageCount={pageCount}
									onPageChange={changePage}
									containerClassName={"paginationBttns"}
									previousLinkClassName={"previousBttn"}
									nextLinkClassName={"nextBttn"}
									disabledClassName={"paginationDisabled"}
									activeClassName={"paginationActive"}
									pageRangeDisplayed={0} // Hides the page numbers
									marginPagesDisplayed={0} // Hides the first and last page numbers
									breakLabel={null} // Removes the "..." separator and ensures no number is shown
								/>
							</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default UserPension;
