import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Navigation() {
	const { t, i18n } = useTranslation();
	return (
		<>
			<ul>
				
				<li>
					<a href="/loans">Business Loan</a>
				</li>
				<li>
					<Link to="/about">{t("footL2")}</Link>
				</li>
				
			</ul>
		</>
	);
}

export default Navigation;
