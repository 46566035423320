import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import close from "../../Sidebar/images/close.png";
import Modal from "react-bootstrap/Modal";
import sus from "./sus.png";
import axios from "axios";
import UserHeader from "../../Header/Header";
const PayPalWithdraw = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [formE, setformE] = useState(false);
	const [token, settoken] = useState("");
	const navigate = useNavigate();
	const [usersname, setusersname] = useState("");
	//Life Cycle Users
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
		}
	}, []);

	const isFormValid = username.trim() !== "" && email.trim() !== "";

	const [searchParams] = useSearchParams();
	const amount = searchParams.get("amount"); // Extract amount from URL
	const onSubmit = (e) => {
		e.preventDefault();
		//Rules

		setformE(true);

		let result = {
			name: "withdraw",
			slug: email + " " + "Paypal",
			description: "Loan withdrawal",
			price: amount,
			username: usersname,
			usertoken: token,
			accept: 0,
		};
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		axios
			.post(
				"https://seamridge.com/backend/public/api/products",
				result,
				axiosConfig
			)
			.then((res) => {})
			.catch((err) => {});
	};
	console.log(formE);

	return (
		<>
			<UserHeader title="Paypal" fke={true} />
			<div className="paypal-withdraw-container">
				<h2>Withdraw via PayPal</h2>
				<p>Provide the following details for withdrawal</p>

				<label>Name or Username</label>
				<form onSubmit={onSubmit}>
					<input
						type="text"
						placeholder="Enter name or username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>

					<label>Email address or Mobile number</label>
					<input
						type="text"
						placeholder="Enter email or mobile"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<button
						className={`continue-btn ${isFormValid ? "active" : ""}`}
						disabled={!isFormValid}
					>
						Continue
					</button>
				</form>

				{/* show */}
				<Modal show={formE} onHide={() => setformE(false)} centered>
					<div className="mo_head">
						<Modal.Title></Modal.Title>
						<span onClick={() => setformE(false)}>
							<img src={close} alt="close" />
						</span>
					</div>
					<div className="mo_body">
						<img src={sus} width={"80px"} alt="check" />
						<h3>Withdrawal request successful!</h3>
						<p>
							You have successfully initiated a withdrawal request. Requests are
							usually processed within 1 hour.
						</p>
					</div>
					<div className="mo_foot">
						<button
							className="mo_btn"
							onClick={() => {
								setformE(false);
								navigate("/app/withdraw"); // Redirect to /app/withdraw
							}}
						>
							Okay
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default PayPalWithdraw;
