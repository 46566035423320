import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../Auth/login/login";
import Dash from "./Dash/Dash";
import moment from "moment";
import UserHeader from "./Header/Header";
import Sidenav from "./Sidebar/Sidenav";
import "./userapp.css";
import History from "./UserHistory/History";
import Market from "./UserMarket/Market";
import { useTranslation } from "react-i18next";
import axios from "axios";
import SideMenu from "./SideMenu/Sidemenu";
function Index() {
	//States
	const { t } = useTranslation();
	const [user, setuser] = useState(null);
	const [User, setUser] = useState([]);
	const [userId, setUserId] = useState("");
	const [trig, setTrig] = React.useState(false);
	const [usersname, setusersname] = useState("");
	const [token, settoken] = useState("");
	const [witminus, setWitminus] = React.useState("");

	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
		}
	}, []);
	//End States
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	useEffect(() => {
		setuser(JSON.parse(localStorage.getItem("SeamR_user")));
	}, []);
	let total = 0;
	User.map((item) => {
		const user_id = item.user_id;
		const wit_id = user_id == userId && item.name == "Loan" && item.accept == 1;
		wit_id && (total = total + parseInt(item.price));
	});
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				if (JSON.parse(localStorage.getItem("SeamR_user")) !== null) {
					const userId = JSON.parse(localStorage.getItem("SeamR_user")).user.id;
					setUserId(userId);
					setUser(result);
					const witminu = result.filter(
						(item) => item.user_id == userId && item.name == "withdraw"
					);
					setWitminus(witminu);
				}
			} catch (err) {}
		}

		fetchMyAPI();
	}, []);
	//End Effects

	//algo
	const uwitminus = User.filter(
		(item) =>
			item.user_id == userId && item.name == "withdraw" && item.accept == 1
	);
	let withprice = 0;
	uwitminus.map((item) => {
		withprice = withprice + parseInt(item.price);
	});

	let tots;

	tots = total - withprice;

	//end algo
	localStorage.setItem(
		"SeamR_users",
		JSON.stringify({
			total: tots,
			limit: total,
		})
	);

	//handling
	const handleOne = () => {
		setTrig(false);
	};
	const handleTwo = () => {
		setTrig(true);
	};

	return (
		<div className="userbg">
			<UserHeader title="Dashboard" />
			<div className="tryt">
				<div className="user_app">
					<Sidenav setTrig={setTrig} />
					<Dash user={user ? user.user : "user"} total={tots} limit={total} />
				</div>
				<div className="user_cont_end">
					<button
						onClick={handleOne}
						className={trig ? "light" : "user_cont_active"}
					>
						{t("history")}
					</button>
					<span className="space_r"></span>
					<History />
					{/* {!trig ? <History /> : <Market />} */}
				</div>
			</div>
		</div>
	);
}
export default Index;
