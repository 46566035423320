import React, { useState } from "react";
import "./CarLoanForm.css"; // Add your updated styles here
import o1 from "./o1.png";
import o2 from "./o2.png";
import o3 from "./o3.png";
import m1 from "./m1.png";
import Nav from "../../Nav/Nav";
import axios from "axios";

const CarLoanForm = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [formData, setFormData] = useState({
		loanType: "",
		purpose: "",
		loanAmount: "",
		educationLevel: "",
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		oftenPayment: "",
		dob: "",
		carloanStatus: "",
		annualIncome: "",
		paymentFrequency: "",
	});

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, 14));
	};

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => {
			const updatedData = { ...prevData, [name]: value };
			console.log(updatedData); // Log the updated formData after every change
			return updatedData;
		});
	};
	const handleSelection = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
		handleNext();
	};

	const handleRadioChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const handleFormSubmit = async () => {
		try {
			// Check if user is already logged in
			const localUser = localStorage.getItem("SeamR_user");

			let userToken;

			if (localUser) {
				// If user exists, use the existing token
				const parsedUser = JSON.parse(localUser);
				console.log(parsedUser, "kkb show");

				userToken = parsedUser.token;
			} else {
				// If user is not found, register the user first
				const registrationResponse = await fetch(
					"https://seamridge.com/backend/public/api/register",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
						},
						body: JSON.stringify({
							name: `${formData.firstName} ${formData.lastName}`,
							email: formData.email,
							phone: formData.phone,
							address: formData.address,
							state: formData.state,
							city: formData.city,
							zip: formData.zip,
							country: formData.country || "United States",
							password: "123456",
							password_confirmation: "123456",
						}),
					}
				);

				const registrationData = await registrationResponse.json();

				if (!registrationResponse.ok) {
					console.error("Registration Error Response:", registrationData);
					throw new Error("Registration failed. Check console for details.");
				}

				console.log("Registration successful:", registrationData);

				userToken = registrationData.user.u_token;

				// Save user data in local storage for future use
				// localStorage.setItem(
				// 	"SeamR_user",
				// 	JSON.stringify({ token: userToken })
				// );
			}

			// Axios configuration for sending product data
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json",
					Authorization: "Bearer " + userToken,
				},
			};

			// Submit product data
			const productResponse = await axios.post(
				"https://seamridge.com/backend/public/api/products",
				{
					name: "Loan",
					price: formData.loanAmount || 0,
					slug: "Car",
					description: "Loan application",
					username: `${formData.firstName}`,
					usertoken: userToken,
					accept: 0,
				},
				axiosConfig
			);

			console.log("Product submitted successfully:", productResponse.data);
			setCurrentPage(12); // Navigate to the success page
		} catch (error) {
			alert("An error occurred. Please try again.");
		}
	};

	console.log(formData.firstName);

	const [showMore, setShowMore] = useState(false);

	return (
		<div className="cbody">
			{/* <Nav /> */}
			<div className="loan-form-container">
				<div className="loan-form">
					{currentPage !== 12 && (
						<div className="progress-bar">
							<div
								className="progress"
								style={{ width: `${(currentPage / 12) * 100}%` }}
							/>
						</div>
					)}

					{/* Page 2 */}
					{currentPage === 1 && (
						<div className="form-page">
							<h2>Why do you need a car loan?</h2>
							<div className="options-grid">
								{["Car Purchase", "Car Refinancing", "Other"].map((option) => (
									<button
										key={option}
										className="option-button"
										onClick={() => handleSelection("purpose", option)}
									>
										{option}
									</button>
								))}
							</div>
						</div>
					)}

					{currentPage === 2 && (
						<div className="form-page">
							<h2>How much do you need?</h2>
							<p>We connect you with loans from $5,000 up to $500,000</p>
							<div className="input-groups">
								<input
									type="number"
									name="loanAmount"
									placeholder="e.g. 15000"
									value={formData.loanAmount}
									onChange={handleInputChange}
								/>
							</div>

							{currentPage !== 13 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={!formData.loanAmount}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{currentPage === 3 && (
						<div className="form-page">
							<h2>What’s your employment status?</h2>
							<div className="options-grid">
								{[
									"Full time",
									"Part time",
									"Freelance",
									"Military",
									"Self employed",
									"Not employed",
									"Other",
								].map((option) => (
									<button
										key={option}
										className="option-button"
										onClick={() => handleSelection("employmentStatus", option)}
									>
										{option}
									</button>
								))}
							</div>
						</div>
					)}

					{currentPage === 4 && (
						<div className="form-page">
							<h2>What’s your name?</h2>
							<div className="options-grid">
								<div className="input-groups">
									<label htmlFor="first-name">First Name</label>
									<input
										type="text"
										id="first-name"
										placeholder="e.g Nathan"
										name="firstName"
										value={formData.firstName}
										onChange={handleInputChange}
									/>
								</div>
								<div className="input-groups">
									<label htmlFor="last-name">Last Name</label>
									<input
										type="text"
										id="last-name"
										placeholder="e.g Pascal"
										name="lastName"
										value={formData.lastName}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							{currentPage !== 13 && (
								<div className="form-navigation">
									<button
										onClick={handleNext}
										disabled={!formData.firstName || !formData.lastName}
									>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{currentPage === 5 && (
						<div className="form-page">
							<h2>What’s your contact email?</h2>
							<div className="input-groups">
								<input
									type="email"
									name="email"
									placeholder="e.g. example@example.com"
									value={formData.email}
									onChange={handleInputChange}
								/>
							</div>
							{currentPage !== 13 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={!formData.email}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{currentPage === 6 && (
						<div className="form-page">
							<h2>What’s your phone number?</h2>
							<div className="input-groups">
								<input
									type="tel"
									name="phone"
									placeholder="e.g. 123-456-7890"
									value={formData.phone}
									onChange={handleInputChange}
								/>
							</div>
							{currentPage !== 13 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={!formData.phone}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}
					{currentPage === 7 && (
						<div className="form-page">
							<h2>When’s your date of birth?</h2>
							<div className="input-groups">
								<input
									type="date"
									name="dob"
									value={formData.dob}
									onChange={handleInputChange}
								/>
							</div>

							{currentPage !== 13 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={!formData.dob}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{currentPage === 8 && (
						<div className="form-page">
							<h2>What’s your annual income?</h2>

							<div className="input-groups">
								<input
									type="number"
									name="annualIncome"
									placeholder="e.g. 50000"
									value={formData.annualIncome}
									onChange={handleInputChange}
								/>
							</div>
							{currentPage !== 13 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={!formData.annualIncome}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}
					{currentPage === 9 && (
						<div className="form-page">
							<h2>How often do you get paid?</h2>
							<div className="options-grid">
								{["Weekly", "Every two weeks", "Twice a month", "Monthly"].map(
									(option) => (
										<button
											key={option}
											className="option-button"
											onClick={() => handleSelection("oftenPayment", option)}
										>
											{option}
										</button>
									)
								)}
							</div>
						</div>
					)}
					{currentPage === 10 && (
						<div className="form-page">
							<h2>What car loan term are you interested in?</h2>
							<div className="options-grid">
								{["12 months", "24 months", "36 months", "48 months"].map(
									(option) => (
										<button
											key={option}
											className="option-button"
											onClick={() => handleSelection("carloanStatus", option)}
										>
											{option}
										</button>
									)
								)}
							</div>
						</div>
					)}

					{currentPage === 11 && (
						<div className="form-page">
							<h2>What’s your address?</h2>

							<div className="input-groups">
								<label htmlFor="address">Address</label>
								<input
									type="text"
									id="address"
									name="address"
									placeholder="e.g. 123 Main Street"
									value={formData.address}
									onChange={handleInputChange}
								/>
							</div>
							<div className="input-groups">
								<label htmlFor="city">City</label>
								<input
									type="text"
									name="city"
									id="city"
									placeholder="City"
									value={formData.city}
									onChange={handleInputChange}
								/>
							</div>
							<div className="input-groups">
								<label htmlFor="state">State</label>
								<input
									type="text"
									name="state"
									id="state"
									placeholder="State"
									value={formData.state}
									onChange={handleInputChange}
								/>
							</div>

							<div className="input-groups">
								<label htmlFor="zip">Zip</label>
								<input
									type="text"
									name="zip"
									id="zip"
									placeholder="ZIP"
									value={formData.zip}
									onChange={handleInputChange}
								/>
							</div>
							{currentPage !== 12 && (
								<div className="form-navigation">
									{currentPage == 11 && (
										<button
											onClick={handleFormSubmit}
											disabled={!formData.state || !formData.zip}
										>
											Submit
										</button>
									)}
								</div>
							)}
						</div>
					)}

					{currentPage === 12 && (
						<div className="form-page form-sucess">
							<img src={m1} alt="check" width={50} />
							<h2>Loan application successful!</h2>
							<p>
								To complete the application process, click the link we sent to
								your email.
							</p>
							<button
								className="form-button"
								onClick={() => (window.location.href = "/")}
							>
								Got it
							</button>
						</div>
					)}
				</div>

				<div className="loan-info">
					<div className="loan-info-content">
						<ul>
							<h4>Access our loan offers in minutes</h4>
							<li>
								<span>
									<img src={o1} alt="check" width={30} />
								</span>
								<span>
									<b>No impact to your credit score</b>
									<p>
										Check out & compare loan offers without hurting your credit
										score.
									</p>
								</span>
							</li>
							<li>
								<span>
									<img src={o2} alt="check" width={30} />
								</span>
								<span>
									<b>40M+ customer loan applications yearly</b>
									<p>
										Join the millions of customers who trust us for their
										financial needs.
									</p>
								</span>
							</li>
							<li>
								<span>
									<img src={o3} alt="check" width={30} />
								</span>
								<span>
									<b>$1B+ financial products disbursed yearly</b>
									<p>
										Get quick access to the right financial product from any of
										our 100+ partners.
									</p>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CarLoanForm;
