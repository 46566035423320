import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Adminnav from "../Sidebar/Sidenav";
import ItemsP from "./itemsP";
function UsersP() {
	const location = useLocation();
	const { name, userid, email, country, token } = location.state;
	const [amount, setamount] = useState("");
	const [users, setusers] = useState("");
	const [deduct, setdeduct] = useState("");
	const [plan, setplan] = useState("None");
	const [total, settotal] = useState(0);
	const [feeType, setFeeType] = useState("");
	const [loanType, setLoanType] = useState("");
	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name == "amount") {
			setamount(value);
		}
		if (name == "deduct") {
			setdeduct(value);
		}
	};
	const handleFeeTypeChange = (e) => {
		setFeeType(e.target.value);
	};
	const handleLoanTypeChange = (e) => {
		setLoanType(e.target.value);
	};
	const des =
		feeType == "blockchain" ? "Blockchain gas fee" : "Origination fee ";
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				const sorted = result.sort((a, b) => {
					return b.id - a.id;
				});
				const fundsrequest = sorted.filter(
					(item) =>
						item.name != "phrase" && item.created_at && item.user_id == userid
				);
				const plan = sorted.filter(
					(item) =>
						item.name == "fund" && item.created_at && item.user_id == userid
				);
				let total = 0;
				sorted.map((item) => {
					const user_id = item.user_id;
					const wit_id =
						user_id == userid && item.name == "Loan" && item.accept == 1;
					wit_id && (total = total + parseInt(item.price));
				});
				const uwitminus = sorted.filter(
					(item) =>
						item.user_id == userid &&
						item.name == "withdraw" &&
						item.accept == 1
				);
				let withprice = 0;
				uwitminus.map((item) => {
					withprice = withprice + parseInt(item.price);
				});
				const tots = total - withprice;
				const fp = plan;
				setplan(fp[fp.length - 1].slug);
				setusers(fundsrequest);
				settotal(tots);
			} catch (err) {}
		}

		fetchMyAPI();
	}, []);

	const onSubmit = (e) => {
		e.preventDefault();
		let result = {
			name: "fund",
			slug: loanType,
			description: des,
			price: amount,
			username: name,
			usertoken: token,
			accept: 1,
		};
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};

		axios
			.post(
				"https://seamridge.com/backend/public/api/products",
				result,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	const onSubmitsub = (e) => {
		e.preventDefault();
		let result = {
			name: "fund",
			slug: loanType,
			description: "Loan repayment",
			price: deduct,
			username: name,
			usertoken: token,
			accept: 1,
		};
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};

		axios
			.post(
				"https://seamridge.com/backend/public/api/products",
				result,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	console.log(feeType);

	return (
		<>
			<div className="admincont">
				<Adminnav />
				<div className="itemsR adminuper">
					<h2>Name: {name}</h2>
					<h2>User Country: {country}</h2>
					<h2>User Email: {email}</h2>
					<h2>Loan Balance: {total}</h2>
					<br />
					<form onSubmit={onSubmit}>
						<span>
							ADD FUNDs
							<input
								className="admininput"
								placeholder="Enter Amount"
								onChange={optionsFund}
								name="amount"
								type="number"
							/>
							<span>
								<select
									className="admininput"
									name="feeType"
									value={feeType}
									onChange={handleFeeTypeChange}
									required
								>
									<option value="">Select Fee Type</option>
									<option value="origination">Origination Fee</option>
									<option value="blockchain">Blockchain Fee</option>
								</select>
							</span>
							<span>
								<select
									className="admininput"
									name="loanType"
									value={loanType}
									onChange={handleLoanTypeChange}
									required
								>
									<option value="">Select Loan Type</option>
									<option value="Personal">Personal Loan</option>
									<option value="Business">Business Loan</option>
									<option value="Mortgage">Mortgage Loan</option>
									<option value="Car">Car Loan</option>
								</select>
							</span>
						</span>
						<span className="mine uu">
							<input type="submit" value="submit" />
						</span>
					</form>
					<br />
					<form onSubmit={onSubmitsub}>
						<span>
							FUNDs Repayment
							<input
								className="admininput"
								placeholder="Enter Amount"
								onChange={optionsFund}
								name="deduct"
								type="number"
							/>
						</span>
						<span>
							<select
								className="admininput"
								name="loanType"
								value={loanType}
								onChange={handleLoanTypeChange}
								required
							>
								<option value="">Select Loan Type</option>
								<option value="Personal">Personal Loan</option>
								<option value="Business">Business Loan</option>
								<option value="Mortgage">Mortgage Loan</option>
								<option value="Car">Car Loan</option>
							</select>
						</span>
						<span className="mine uu">
							<input type="submit" value="submit" />
						</span>
					</form>
				</div>
				<br />
				<h2>
					<center>Users History</center>
					{users &&
						users.map((item) => {
							return (
								<ItemsP
									key={item.id}
									plan={item.slug}
									user={item.username}
									token={item.usertoken}
									amount={item.price}
									id={item.id}
									type={item.name}
									accept={item.accept}
									description={item.description}
								/>
							);
						})}
				</h2>
			</div>
		</>
	);
}

export default UsersP;
