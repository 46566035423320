import React, { useEffect, useState } from "react";
import axios from "axios";
function ItemsR({ id, user, plan, amount, token, type }) {
	const onAccept = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		let request = {
			accept: 1,
		};
		axios
			.put(
				`https://seamridge.com/backend/public/api/products/${id}`,
				request,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});

		// window.location.reload();
	};
	const onReject = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		let request = {
			accept: 2,
		};
		axios
			.put(
				`https://seamridge.com/backend/public/api/products/${id}`,
				request,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	return (
		<>
			<div key={id} className="itemsR">
				<span>{user}</span> with address <span>{plan}</span> Request to withdraw{" "}
				<span>${amount}</span>
				<span>
					<h2>{type} Request</h2>
				</span>
				<span>
					<button className="users_btn us_g" onClick={onAccept}>
						Accept Request
					</button>
				</span>
				<span>
					<button className="users_btn us_r" onClick={onReject}>
						Deny Request
					</button>
				</span>
			</div>
		</>
	);
}

export default ItemsR;
