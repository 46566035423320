import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/features.css";
import featuresOne from "./features1.png";
import featuresTwo from "./features2.png";
import o1 from "./1.png";
import o2 from "./2.png";
import o3 from "./3.png";
import o4 from "./4.png";
import check from "./check.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Features({ className }) {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	const [tab, setTab] = useState("setting");
	const handleClick = (e, value) => {
		e.preventDefault();
		setTab(value);
	};
	return (
		<div className="">
			<main className="main">
				<div className="loan-sec">
					<h3>Enjoy an easy loan process</h3>

					{/* Loan Process Section */}
					<section className="loan-process">
						<div className="text-content">
							<ul>
								<li>
									<span>
										{" "}
										<img src={o1} alt="number" width={30} />
									</span>{" "}
									<span>
										<b> Check your rate</b>
										<p>
											Get pre-qualified in just a few minutes. Checking your
											rate is free and won't impact your credit score.
										</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o2} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Select a loan offer</b>
										<p>
											Sign up, apply for a loan, and select a loan offer that
											suits your needs.
										</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o3} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Finalize your loan offer</b>
										<p>Secure your funding by accepting the loan offer.</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o4} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Get credited</b>
										<p>Receive your money into your account within 24 hours.</p>
									</span>
								</li>
							</ul>
						</div>
						<div className="image-container">
							<img src={featuresOne} alt="Easy Loan Process" />
						</div>
					</section>
				</div>
				<div className="why-sec">
					<h3>Why choose Seamridge?</h3>
					{/* Why Choose Seamridge Section */}
					<section className="why-choose">
						<div className="image-container">
							<img src={featuresTwo} alt="Why Choose Seamridge" />
						</div>
						<div className="text-content">
							<ul>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>
									<span>
										<b>Fast pre-approvals and disbursements</b>
										<p>
											Need funds fast? We offer quick pre-approvals (often
											within minutes) and rapid disbursement upon approval.
										</p>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Competitive interest rates and transparent Features</b>
										<p>
											Enjoy competitive rates, clear fees, and no early
											repayment penalties with Seamridge.
										</p>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Personalized loan options and dedicated support</b>
										<p>
											We offer personalized loan options (personal, business,
											mortgage, car) with dedicated support.
										</p>
									</span>
								</li>
							</ul>
							<a className="main-btn" href="/loans">Apply for loan</a>
						</div>
					</section>
				</div>
			</main>
		</div>
	);
}

export default Features;
