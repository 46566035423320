import React, { useEffect, useState } from "react";
import axios from "axios";
function ItemsP({ id, user, plan, amount, token, type, accept, description }) {
	let des = type === "withdraw" ? "Loan withdrawal" : "Loan credited";
	const onAccept = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		let request = {
			accept: 1,
			description: des,
		};
		axios
			.put(
				`https://seamridge.com/backend/public/api/products/${id}`,
				request,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});

		// window.location.reload();
	};
	const onReject = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		let request = {
			accept: 2,
			description: "Loan rejected",
		};
		axios
			.put(
				`https://seamridge.com/backend/public/api/products/${id}`,
				request,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	const onDelete = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		axios
			.delete(
				`https://seamridge.com/backend/public/api/products/${id}`,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	return (
		<>
			<div key={id} className="itemsR">
				{type == "Loan" ? (
					<div>
						<span>{plan} Loan with</span> amount: <span>${amount}</span>
					</div>
				) : (
					<div>
						
						<span>
							{type == "fund" ? "Origination or Blockchain Fee" : "Withdrawal"} of 	
						</span>{" "}
						amount: <span>${amount}</span> <br />
						{plan} 
					</div>
				)}
				{accept != 1 ? (
					<div>
						<span>
							<button className="users_btn us_g" onClick={onAccept}>
								Accept Request
							</button>
						</span>
						<span>
							<button className="users_btn us_r" onClick={onReject}>
								Reject Request
							</button>
						</span>
					</div>
				) : (
					<span>
						<button className="users_btn uu" onClick={onDelete}>
							Delete Transaction
						</button>
					</span>
				)}
			</div>
		</>
	);
}

export default ItemsP;
