import React, { useEffect } from "react";
import "../css/growth.css";
import growth1 from "./growth1.png";
import growth2 from "./growth2.png";
import growth3 from "./growth3.png";
import growth4 from "./growth4.png";
import growth5 from "./growth5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Growth() {
	const settings = {
		centerMode: true,

		draggable: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 3,
		slidesToScroll: 2,
		dots: true,
	};
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="growthF" data-aos="fade-down">
			<div className="container">
				<div className="pricecont" data-aos="fade-right">
					<h4>BUSINESS PARTNERS</h4>
				</div>
				<div className="imdsl">
					<Slider {...settings}>
						<img src={growth1} alt="pic" />
						{/* <img src={growth2}  style={"60px"}  alt="pic" /> */}
						<img src={growth3} alt="pic" />
						<img src={growth4} alt="pic" />
						<img src={growth5} alt="pic" />
					</Slider>
				</div>
			</div>
		</div>
	);
}

export default Growth;
