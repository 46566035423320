import React from "react";
import Moment from "moment";

const UserTrans = ({ created_at, price, accept, description }) => {
	const formattedDate = Moment(created_at).format("DD MMM, YYYY");
	const formattedAmount = Number(price).toLocaleString("en-US");

	const transactionStatus =
		{
			0: "Pending",
			1: "Paid",
		}[accept] || "Pending";

	return (
		<div className="UserTrans">
			<div className="col-date">{formattedDate}</div>
			<div className="col-amount">$ {formattedAmount}</div>
			<div className="col-status ">
				<span className="sta">
                <span className="circle"></span>
				{transactionStatus}
                </span>
			</div>
			<div className="col-description">{description}</div>
		</div>
	);
};

export default UserTrans;
