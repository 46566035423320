import React, { useState, useEffect } from "react";
import empty from "./img/empty.png";
import UserTrans from "./UserTrans";
function WithdrawTable() {
	const [user, setUser] = React.useState([]);
	const [userId, setUserId] = React.useState("");
	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 15;
	const pagesVisited = pageNumber * usersPerPage;
	const sorted = user.sort((a, b) => {
		return b.id - a.id;
	});
	var map = sorted.filter(
		(item) =>
			userId == item.user_id  &&
			item.name == "withdraw"
			
	);
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				if (JSON.parse(localStorage.getItem("SeamR_user")) !== null) {
					const userId = JSON.parse(localStorage.getItem("SeamR_user")).user.id;
					setUserId(userId);
					setUser(result);
				}
			} catch (err) {}
		}

		fetchMyAPI() && fetchMyAPI();
	}, []);
	// Get current posts
	const currentPosts = map.slice(pagesVisited, pagesVisited + usersPerPage);
	// Change page
	const pageCount = Math.ceil(map.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	return (
		<div id="history" className="history withp newhistory">
			{currentPosts.length == 0 ? (
				<center>
					<img className="hist_img" src={empty} alt="no transactions" />
				</center>
			) : (
				<div>
					<div className="transact-header">
						<div className="col-date">Date</div>
						<div className="col-amount">Amount</div>
						<div className="col-status ">Status</div>
						<div className="col-description">Description</div>
					</div>
					<div className="H_line"></div>
					<div className="transactions">
						{currentPosts.map((item) => {
							return (
								<UserTrans
									key={item.id}
									id={item.id}
									description={item.description}
									name={item.name}
									accept={item.accept}
									slug={item.slug}
									dbdate={item.created_at}
									price={item.price.toLocaleString("en-US")}
									map={map}
								/>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default WithdrawTable;
