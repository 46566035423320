import React, { useState, useEffect } from "react";
import "./history.css";
import "../Pages/css/fund.css";
import "../Pages/css/usersPages.css";
import empty from "./img/empty.png";
import ReactPaginate from "react-paginate";
import UserTrans from "./UserTrans";
function History() {
	const [user, setUser] = React.useState([]);
	const [userId, setUserId] = React.useState("");
	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 6;
	const pagesVisited = pageNumber * usersPerPage;
	const sorted = user.sort((a, b) => {
		return b.id - a.id;
	});
	var map = sorted.filter(
		(item) =>
			userId == item.user_id &&
			item.slug !== "user funded" &&
			item.name !== "phrase"
	);
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				if (JSON.parse(localStorage.getItem("SeamR_user")) !== null) {
					const userId = JSON.parse(localStorage.getItem("SeamR_user")).user.id;
					setUserId(userId);
					setUser(result);
				}
			} catch (err) {}
		}

		fetchMyAPI() && fetchMyAPI();
	}, []);
	// Get current posts
	const currentPosts = map.slice(pagesVisited, pagesVisited + usersPerPage);
	// Change page
	const pageCount = Math.ceil(map.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	return (
		<div id="history" className="history">
			{currentPosts.length == 0 ? (
				<center>
					<img className="hist_img" src={empty} alt="no transactions" />
				</center>
			) : (
				<div>
					<div className="transact-header userhis-header">
						<div className="col-date">Date</div>
						<div className="col-amount">Amount</div>
						<div className="col-status ">Status</div>
						<div className="col-description">Description</div>
					</div>
					<div className="H_line"></div>
					<div className="transactions">
						{currentPosts.map((item) => {
							return (
								<UserTrans
									key={item.id}
									id={item.id}
									description={item.description}
									name={item.name}
									accept={item.accept}
									slug={item.slug}
									dbdate={item.created_at}
									price={item.price.toLocaleString("en-US")}
									map={map}
								/>
							);
						})}
					</div>
					<div className="histpg">
						<span className="foot_showing">
							Showing {pageNumber + 1} - {pageCount}{" "}
							<span>of {pageCount} items</span>
						</span>
						<span>
							<ReactPaginate
								previousLabel={"< Previous"}
								nextLabel={"Next >"}
								pageCount={pageCount}
								onPageChange={changePage}
								containerClassName={"paginationBttns"}
								previousLinkClassName={"previousBttn"}
								nextLinkClassName={"nextBttn"}
								disabledClassName={"paginationDisabled"}
								activeClassName={"paginationActive"}
								pageRangeDisplayed={0} // Hides the page numbers
								marginPagesDisplayed={0} // Hides the first and last page numbers
								breakLabel={null} // Removes the "..." separator and ensures no number is shown
							/>
						</span>
					</div>
				</div>
			)}
		</div>
	);
}

export default History;
