import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "./logo.png";
import { useTranslation } from "react-i18next";
import useScript from "../Widget/useScript";
function Footer({ className }) {
	const { t } = useTranslation();
	const currentTime = new Date();
	const year = currentTime.getFullYear();
	return (
		<>
			<section className={`appie-footer-area ${className || ""}`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="footer-about-widget">
								<div className="appie-logo-box">
									<Link to="/">
										<img src={logo} alt="logo" width={"168px"} />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation">
								<ul>
									<li>
										<Link to="/loans">Our loan offerings</Link>
									</li>
									<li>
										<Link to="/check-rate">Check my rate</Link>
									</li>
									<li>
										<a href="#" alt="faq">
											Rates & Terms
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation footerProducts">
								<ul>
									<li>
										<Link to="/#">Support</Link>
									</li>
									<li>
										<Link to="/contact">Contact</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation">
								<ul>
									<li>
										<a
											href="https://github.com/seamridgeinc/docs/blob/main/Policy/policy.md"
											alt="policy"
										>
											Privacy & Legal
										</a>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeinc/docs/blob/main/Terms/Terms.md"
											alt="terms"
										>
											Terms & Conditions
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div className="footer-copyright">
								<hr />
								<br />
								<p>
									Your repayment terms will depend on your interest rate,
									origination fee, loan amount, and loan term. Example — A loan
									of $16,000 at 6.315% APR will have a $550 origination fee and
									a monthly payment of $378.08 for 48 months.
								</p>
								<p>
									Seamridge offer fixed rates between 4.475% APR and 8.74% APR
									for loan amounts from $1,000 to $40,000. The minimum rate for
									loan amounts above $15,000 is 5.85% APR. The minimum loan
									amount and APR may vary in certain states. Rates depicted are
									accurate as of November 7, 2024. Please see our Rates and
									Terms page for specific details.
								</p>
								<p>
									The origination fee will be deposited on the platform and is
									based on your loan amount, term, and credit quality. Lenders
									do not charge any late, bounced check, failed ACH, or other
									fees. This one-time fee will be deposited when
									your loan is issued.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Footer;
