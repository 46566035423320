import React, { useEffect, useState } from "react";
import Frame from "./Frame.png";
import check from "./3.png";
import bannerT from "./bannerT.png";
import FrameBg from "./Frame3.png";
import gif from "./banerG.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css"; // import css


function Hero() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<>
			<section className="appie-hero-area">
				<div className="container">
					<div className="container_bg cont-n">
						<div className="row align-items-center" data-aos="fade-left">
							<div className="col-lg-6">
								<div className="appie-hero-content">
									<br />
									<h1 className="appie-title">
										Access loans with low fixed rates
									</h1>
									<p className="pbody">
										Personal loans with your best interests at heart
									</p>
									<ul>
										<li>
											<a href="/loans">Apply for loan</a>
											<a href="/check-rate">Check my rate</a>
										</li>
									</ul>
									<p style={{ fontSize: "16px" }}>
										Checking your rate is free and won’t impact your credit
										score*
									</p>
									<ul className="Hero_checks">
										<li>
											<img src={check} alt="check" width={"20px"} />
											<span>Personal loans</span>
										</li>
										<li>
											<img src={check} alt="check" width={"20px"} />
											<span>Business loans</span>
										</li>
									</ul>
									<ul className="Hero_check">
										<li>
											<img src={check} alt="check" width={"20px"} />
											<span>Morgage</span>
										</li>
										<li>
											<img src={check} alt="check" width={"20px"} />
											<span>Car loans</span>
										</li>
									</ul>
									<div className="lowerDk">
										<div className="trustImgList">
											<img src={Frame} alt="Binance" width={"240px"} />
											{/* <img src={trustEight} alt="crypto" width={"100px"} />
											<img src={trustOne} alt="coinbase" width={"100px"} /> */}
										</div>
									</div>
									<br />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="appie-hero-thumb">
									<div
										className="thumb wow animated fadeInUp"
										data-wow-duration="2000ms"
										data-wow-delay="200ms"
										id="HeroVid"
									>
										<img src={gif} alt="gif" className="gifbanner" />
										<img src={FrameBg} alt="gif" className="gifbg"  />
										{/* <Player
											poster={
												t("lang") == "en"
													? Eng
													: t("lang") == "de"
													? Ger
													: t("lang") == "it"
													? Ita
													: t("lang") == "es" && Spa
											}
											src={t("lang") == "en"
											? Heroeng
											: t("lang") == "de"
											? Heroger
											: t("lang") == "it"
											? Heroita
											: t("lang") == "es" && Herospa}
										>
											<BigPlayButton position="center" />
										</Player> */}
									</div>
									<img className="bannert" src={bannerT} alt="Trust" width={"240px"} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Hero;
