import { useState } from "react";
import dash from "./images/dash.png";
import fundw from "./images/fundw.png";
import withdraw from "./images/withdraw.png";
import referrals from "./images/pension.png";
import logout from "./images/logout.png";
import settings from "./images/settings.png";
import pension from "./images/pension.png";
import help from "./images/help.png";
import close from "./images/close.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sidebar.css";
import { DollarSign } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Sidebar({ openClass, setTrig, on, setOn }) {
	const { t } = useTranslation();
	const [show, setShow] = useState(false); // Added state for modal

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Handling Sidebar Navigation
	const handleOne = () => {
		setTrig(false);
		if (on) setOn(!on);
	};

	const handleTwo = () => {
		setTrig(true);
		if (on) setOn(!on);
	};
	function logoutbtn() {
		localStorage.removeItem("SeamR_user");
		window.location.reload();
	}
	return (
		<nav className={`wnav ${openClass === "open" ? "opneSidebar" : ""}`}>
			<ul className="navlist">
				<li>
					<Link className="menu-item" to="/app">
						<img src={dash} alt="Dashboard" />
						{t("Dashboard")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" to="/app/loans">
						<img src={pension} alt="Pension" />
						Loans
					</Link>
				</li>
				<li>
					<Link className="menu-item" to="/app/withdraw">
						<img src={withdraw} alt="Withdrawals" />
						Withdrawals
					</Link>
				</li>
				<li>
					<Link className="menu-item" to="/app/payments">
						<img src={fundw} alt="Payments" />
						Payments
					</Link>
				</li>
				<li>
					<Link className="menu-item" to="/app/support">
						<img src={help} alt="Support" />
						Support
					</Link>
				</li>
				<li>
					<Link className="menu-item" to="/app/settings">
						<img src={settings} alt="Settings" />
						Settings
					</Link>
				</li>
				<li>
					<Link className="menu-item sdm" to="/app/referrals">
						<DollarSign size={20} />
						Refer & Get Cash Reward
					</Link>
				</li>
				<li>
					<Link className="menu-item" onClick={handleShow}>
						<img src={logout} alt="Logout" />
						Logout
					</Link>

					<Modal show={show} onHide={handleClose} centered>
						<div className="mo_head">
							<Modal.Title> Logout</Modal.Title>

							<span onClick={handleClose}>
								<img src={close} alt="Payments" />
							</span>
						</div>

						<Modal.Body>
							Are you sure you want to logout from this account?
						</Modal.Body>
						<div className="mo_foot">
							<button className="chek" onClick={handleClose}>
								Cancel
							</button>
							<Button variant="danger" onClick={logoutbtn}>
								Logout
							</Button>
						</div>
					</Modal>
				</li>
			</ul>
		</nav>
	);
}

export default Sidebar;
