import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "../css/usersPages.css";
import AccountManager from "./AccountManager";
import { useTranslation } from "react-i18next";
function Account() {
	const { t } = useTranslation();
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	return (
		<div className="userbg">
			<UserHeader title="Settings" />
			<div className="user_app">
				<Sidenav />
				<div className="Account_set">
					<AccountManager />
				</div>
			</div>
		</div>
	);
}

export default Account;
