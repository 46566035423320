import React, { useState } from "react";
import "../css/forgot.css";
import axios from "axios";
import Nav from "../../Nav/Nav";
import { useTranslation } from "react-i18next";

const Forgot = () => {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [send, setSend] = useState(false);

	const handleInputChange = (e) => {
		setEmail(e.target.value);
	};

	const handleMail = async (e) => {
		e.preventDefault();
		setSend(true);

		try {
			const response = await axios.post(
				"https://seamridge.com/backend/public/api/forgot-password",
				{ email }
			);
			setMsg(response.data.status);
		} catch (error) {
			setMsg("An error occurred. Please try again.");
		}
	};

	return (
		<>
			<Nav />
			<div className="container">
				<div className="forgot_cont">
					<h2>{t("forgot5")}</h2>
					{msg ? (
						<div className="f_sucess">
							{msg} {t("forgot8")}
						</div>
					) : (
						<form onSubmit={handleMail}>
							<div className="contact-form reg-form forget_info">
								<p>{t("forgot6")}</p>
								<input
									type="email"
									placeholder="Enter your email"
									value={email}
									onChange={handleInputChange}
									required
								/>
							</div>
							<div className="forgot_btn">
								<button type="submit" disabled={send}>
									{send ? t("forgot7") : t("forgot5")}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</>
	);
};

export default Forgot;
