import React from "react";
import Footer from "../../Footer/Footer";
import Nav from "../../Nav/Nav";
import "../css/about.css";
import AboutDetails from "./aboutDetails";
import AboutHero from "./AboutHero";
import Aboutservices from "./aboutServices";
import AboutTeam from "./AboutTeam";
function About() {
	return (
		<div>
			<Nav />
			<AboutHero />
			<AboutDetails />
			{/* <Aboutservices /> */}
			<AboutTeam />
			<Footer />
		</div>
	);
}

export default About;
