import React from "react";
import "./sidebar.css";
import Sidebar from "./Sidebar";
import hambuger_o from "./hambuger_o.png";
import hambuger_c from "./hambuger_c.png";
// import { Menu } from "styled-icons/feather/Menu";

function Sidenav({ close, open, withdrawable, open_I, close_I, total, title, setTrig }) {
	const [on, setOn] = React.useState(false);

	const handleOn = () => {
		setOn(!on);
	};
	return (
		<div className={`Nav Nav_mob ${on ? "overL" : ""}`}>
			<div className="Nav_up "></div>
			<span className="hamo"></span>
			<aside className={`hamburg ${on ? "to-right" : ""}`}>
				<a onClick={handleOn} className="App" style={{ cursor: "pointer" }}>
					{on ? (
						<img src={hambuger_c} alt="hambuger close" />
					) : (
						<img src={hambuger_o} alt="hambuger" />
					)}
				</a>
			</aside>
			{on && (
				<Sidebar
					close={close}
					openClass="open"
					open={open}
					close_I={close_I}
					open_I={open_I}
					withdrawable={withdrawable}
					total={total}
					setTrig={setTrig} 
					on={on}
					setOn={setOn}
				/>
			)}
			
		</div>
	);
}

export default Sidenav;
