import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../css/fund.css";
import sus from "./sus.png";
import close from "../../Sidebar/images/close.png";
import axios from "axios";
import { useTranslation } from "react-i18next";

function AccountManager() {
	const { t } = useTranslation();
	const [users, setUser] = useState(null);
	const [showEdit, setShowEdit] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [msg, setMsg] = useState("");

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem("SeamR_user")));
	}, []);

	const user = users ? users.user : null;
	const userName = user ? user.name : "User";
	const userInitials = userName
		.split(" ")
		.map((word) => word.charAt(0))
		.join("")
		.toUpperCase();

	const handleEditClose = () => setShowEdit(false);
	const handleEditShow = () => setShowEdit(true);

	const handlePasswordClose = () => setShowPassword(false);
	const handlePasswordShow = async () => {
		if (user?.email) {
			try {
				const response = await axios.post(
					"https://seamridge.com/backend/public/api/forgot-password",
					{ email: user.email }
				);
				setMsg(response.data.status);
			} catch (error) {
				setMsg("An error occurred. Please try again.");
			}
		}
		setShowPassword(true);
	};

	return (
		<>
			<div className="container">
				<span className="user_p">
					{user && (
						<div className="set_userp">
							<div className="user_initials">{userInitials}</div>
							<h4>Profile Picture</h4>
						</div>
					)}
					<div className="settings_content">
						<span>Full name</span> <b>{user?.name}</b>
					</div>
					<div className="settings_contents">
						<span>Email</span> <b>{user?.email}</b>
					</div>
					<div className="settings_content">
						<span>Phone Number</span> <b>+1{user?.phone}</b>
					</div>
					<div className="settings_content">
						<span>Address</span>{" "}
						<b>
							{user && (
								<>
									{user.address}, {user.city}, {user.state}, {user.zip}
									<br />
									{user.country}
								</>
							)}
						</b>
					</div>
					<button onClick={handleEditShow}>Edit Information</button>
					{/* Hanlde Pass Reset */}
					<div className="set_pass">
						<h4>Password</h4>
						<p>Your password protects your access to your seamridge account.</p>
						<button onClick={handlePasswordShow}>Change Password</button>
					</div>

					{/* Edit Information Modal */}
					<Modal show={showEdit} onHide={handleEditClose} centered>
						<div className="mo_head">
							<Modal.Title> </Modal.Title>
							<span onClick={handleEditClose}>
								<img src={close} alt="close" />
							</span>
						</div>
						<div className="mo_body">
							<img src={sus} width={"80px"} alt="check" />
							<h3>Profile Information Change!</h3>
							<p>
								To change profile information, please email us at <br />{" "}
								<b>
									<a href="mailto:infochange@seamridge.com">
										infochange@seamridge.com
									</a>
								</b>{" "}
								with the subject <b>Profile Information Change</b>
							</p>
						</div>
						<div className="mo_foot">
							<button className="mo_btn" onClick={handleEditClose}>
								Okay
							</button>
						</div>
					</Modal>

					{/* Change Password Modal */}
					<Modal show={showPassword} onHide={handlePasswordClose} centered>
						<div className="mo_head">
							<Modal.Title></Modal.Title>
							<span onClick={handlePasswordClose}>
								<img src={close} alt="close" />
							</span>
						</div>
						<div className="mo_body">
							<img src={sus} width={"80px"} alt="check" />
							<h3>Password Reset Request Sent!</h3>
							<p>
								An email has been sent to <b>{user?.email}</b> with instructions
								to reset your password. Please check your inbox and spam folder.
							</p>
							{msg && <p style={{ color: "green" }}>{msg}</p>}
						</div>
						<div className="mo_foot">
							<button className="mo_btn" onClick={handlePasswordClose}>
								Okay
							</button>
						</div>
					</Modal>
				</span>
			</div>
		</>
	);
}

export default AccountManager;
