import React from "react";
import Moment from "moment";

const UserTrans = ({ created_at, price, accept, description, name }) => {
	const formattedDate = Moment(created_at).format("DD MMM, YYYY");
	const formattedAmount = Number(price).toLocaleString("en-US");

	let transactionStatus =
		{
			0: "Pending",
			1: "Sucessful",
		}[accept] || "rejected";
	// If the description is "origination fee", override the status to "Paid"
	if (description === "Origination fee" || description === "origination fee") {
		transactionStatus = "Paid";
	}

	// If the description is "origination fee", override the status to "Paid"
	if (name == "Loan") {
		transactionStatus =
			{
				0: "Pending",
				1: "Approved",
			}[accept] || "rejected";
	}
	return (
		<div className="UserTrans UserHistory">
			<div className="col-date">{formattedDate}</div>
			<div className="col-amount">$ {formattedAmount}</div>
			<div className="col-status ">
				<div className="col-status">
					<span className="sta">
						<span
							className={
								transactionStatus == "Approved" ||
								transactionStatus == "Paid" ||
								transactionStatus == "Sucessful"
									? "circle"
									: transactionStatus == "Pending"
									? "yellow"
									: "red"
							}
						></span>
						{transactionStatus}
					</span>
				</div>
			</div>
			<div className="col-description">{description}</div>
		</div>
	);
};

export default UserTrans;
