import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import { useTranslation } from "react-i18next";
function Drawer({ drawer, action }) {
	const { t, i18n } = useTranslation();
	const [itemSize, setSize] = useState("0px");
	const [item, setItem] = useState("home");
	const handler = (e, value) => {
		e.preventDefault();
		const getItems = document.querySelectorAll(`#${value} li`).length;
		if (getItems > 0) {
			setSize(`${43 * getItems}px`);
			setItem(value);
		}
	};
	return (
		<>
			<div
				onClick={(e) => action(e)}
				className={`off_canvars_overlay ${drawer ? "active" : ""}`}
			></div>
			<div className="offcanvas_menu">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div
								className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
							>
								<div className="canvas_close">
									<a href="#" onClick={(e) => action(e)}>
										<i className="fa fa-times"></i>
									</a>
								</div>
								<div className="offcanvas-brand mb-40">
									<div className="appie-logo-box">
										<Link to="/">
											<img src={logo} alt="logo" width={"140px"} />
										</Link>
									</div>
								</div>
								<div id="menu" className="text-left ">
									<ul className="offcanvas_main_menu">
										<li id="pages" className="menu-item-has-children active">
											<span className="menu-expand"></span>
											<a href="/loans">{t("Business Loans")}</a>
										</li>
										<li className="menu-item-has-children active">
											<span className="menu-expand"></span>
											<Link to="/about">{t("footL2")}</Link>
										</li>

										<li className="menu-item-has-children active">
											<Link to="/check-rate">Check my rate</Link>
										</li>
									</ul>
								</div>
								<div className="offcanvas-social">
									<ul className="text-center">
										<li>
											<a href="https://github.com/seamridgeinc">
												<i className="fab github"></i>
											</a>
										</li>
										<li>
											<a href="https://github.com/seamridgeinc">
												<i className="fab fa-linkedin"></i>
											</a>
										</li>
										<li>
											<a href="https://github.com/seamridgeinc">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										{/* <li>
											<a href="https://github.com/seamridgeinc">
												<i className="fab fa-telegram"></i>
											</a>
										</li> */}
									</ul>
								</div>
								<div className="footer-widget-info">
									<ul>
										<li>
											<a href="mailto: hello@seamridge.com">
												<i className="fal fa-envelope"></i> Hello@seamridge.com
											</a>
										</li>
										{/* <li>
											<a href="#">
												<i className="fal fa-phone"></i> +(642) 342 762 44
											</a>
										</li> */}
										<li>
											<a href="#">
												<i className="fal fa-map-marker-alt"></i> Sondanella 104
												- 7545 Guarda, Switzerland
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Drawer;
