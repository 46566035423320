import React, { useState } from "react";
import "./Checkrate.css"; // Add your updated styles here
import m1 from "./m1.png";
import Nav from "../../Nav/Nav";

const Checkrate = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [formData, setFormData] = useState({
		loanType: "",
		numbers: [],
		loanAmount: "", // Add loanAmount to state
	});

	const total = formData.numbers.reduce((acc, num) => acc + num, 0);

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, 12));
	};

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
	};

	const handleSelection = (loanType, number) => {
		setFormData((prevData) => ({
			...prevData, // Preserve existing values including loanAmount
			loanType,
			numbers: [...prevData.numbers, number], // Append new number
		}));
		setCurrentPage((prevPage) => prevPage + 1); // Move to the next page
	};

	const handleLoanAmountChange = (event) => {
		setFormData({ ...formData, loanAmount: event.target.value });
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => {
			const updatedData = { ...prevData, [name]: value };
			console.log(updatedData); // Log the updated formData after every change
			return updatedData;
		});
	};
	console.log("kkk", formData.loanAmount);

	const handleRadioChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [showMore, setShowMore] = useState(false);

	return (
		<div className="cbody ">
			<Nav />
			<div className="loan-form-container rate-form-container">
				<div className="rate-form">
					{currentPage !== 12 && (
						<div className="progress-bar">
							<div
								className="progress"
								style={{ width: `${(currentPage / 14) * 100}%` }}
							/>
						</div>
					)}
					{/* Page 1 */}
					{currentPage === 1 && (
						<div className="form-page">
							<h2>What loan offering are you looking for?</h2>
							<p>
								To ensure we’re on the same page, could you share a bit about
								your loan needs?
							</p>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("Personal Loan", 9)}
								>
									Personal Loan
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Business Loan", 3)}
								>
									Business Loan
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Mortgage", 0.9)}
								>
									Mortgage
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Car Loan", 3.1)}
								>
									Car Loan
								</button>
							</div>
						</div>
					)}

					{/* Page 2 */}
					{currentPage === 2 && (
						<div className="form-page">
							<h2>How much would you like to borrow?</h2>
							<div className="options-price input-wrapper">
								<p>We connect you with loans from $10,000 up to $4M</p>
								<input
									type="number"
									min={1}
									className="icon"
									name="loanAmount"
									value={formData.loanAmount || ""}
									onChange={handleLoanAmountChange}
								/>
							</div>
							{currentPage !== 12 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={currentPage === 14}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{/* Page 3 */}
					{currentPage === 3 && (
						<div className="form-page">
							<h2>What is the intended purpose of this loan?</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("Medical Dental", 0.5)}
								>
									Medical Dental
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Household Expenses", 0.6)}
								>
									Household Expenses
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Debt Consolidation", 0.7)}
								>
									Debt Consolidation
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Student Loan Refinance", 0.4)}
								>
									Student Loan Refinance
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Special Occasion", 0.3)}
								>
									Special Occasion
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Vacation", 0.5)}
								>
									Vacation
								</button>
							</div>
							{/* Toggle Button */}
							<p className="see-more" onClick={() => setShowMore(!showMore)}>
								{showMore ? "Collapse options" : "Show more options"}
							</p>
							{showMore && (
								<>
									<button className="option-button">
										Student Loan Refinance
									</button>
									<button className="option-button">Special Occasion</button>
									<button className="option-button">Vacation</button>
								</>
							)}
						</div>
					)}

					{/* Page 4 */}
					{currentPage === 4 && (
						<div className="form-page">
							<h2>What’s your employment status?</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("Full time", 0.6)}
								>
									Full time
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Part time", 0.5)}
								>
									Part time
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Freelance", 0.6)}
								>
									Freelance
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Military", 0.2)}
								>
									Military
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Self employed", 0.4)}
								>
									Self employed
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Not employed", 0.8)}
								>
									Not employed
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Other", 0.8)}
								>
									Other
								</button>
							</div>
						</div>
					)}

					{/* Page 5 */}
					{currentPage === 5 && (
						<div className="form-page">
							<h2>What’s your highest completed level of education?</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("High School", 0.3)}
								>
									High School
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Associate Degree", 0.84)}
								>
									Associate Degree
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Bachelor's Degree", 0.38)}
								>
									Bachelor's Degree
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Master's Degree", 0.5)}
								>
									Master's Degree
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Doctorate", 0.5)}
								>
									Doctorate
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Still Enrolled", 0.6)}
								>
									Still Enrolled
								</button>
							</div>
						</div>
					)}

					{/* Page 6 */}
					{currentPage === 6 && (
						<div className="form-page">
							<h2>Do you presently own a vehicle?</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("Yes, fully paid off", 0.1)}
								>
									Yes, fully paid off
								</button>
								<button
									className="option-button"
									onClick={() =>
										handleSelection("Yes, currently leasing it", 0.3)
									}
								>
									Yes, currently leasing it
								</button>
								<button
									className="option-button"
									onClick={() =>
										handleSelection("Yes, with an active auto loan", 0.8)
									}
								>
									Yes, with an active auto loan
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("No", 0.2)}
								>
									No
								</button>
							</div>
						</div>
					)}

					{/* Page 7 */}
					{currentPage === 7 && (
						<div className="form-page">
							<h2>What’s your annual income?</h2>
							<input type="number" min={1} className="icon" name="income" />
							{currentPage !== 12 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={currentPage === 14}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{/* Page 8 */}
					{currentPage === 8 && (
						<div className="form-page">
							<h2>What’s your address?</h2>
							<div className="input-groups">
								<label htmlFor="address">Address</label>
								<input
									type="text"
									id="address"
									placeholder="eg. 546 3rd Avenue..."
									name="address"
								/>
							</div>
							<div className="input-groups">
								<label htmlFor="city">City</label>
								<input
									type="text"
									id="city"
									placeholder="e.g New York"
									name="city"
								/>
							</div>
							<div className="input-groups">
								<label htmlFor="State">State</label>
								<input
									type="text"
									id="State"
									placeholder="Enter State"
									name="State"
								/>
							</div>
							<div className="input-groups">
								<label htmlFor="zip">Zip Code</label>
								<input
									type="text"
									id="zip"
									placeholder="e.g 10010"
									name="zip"
								/>
							</div>
							{currentPage !== 12 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={currentPage === 14}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{/* Page 9 */}
					{currentPage === 9 && (
						<div className="form-page">
							<h2>Do you rent or own your own home?</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleSelection("Rent", 0.5)}
								>
									Rent
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Own outright", 0.7)}
								>
									Own outright
								</button>
								<button
									className="option-button"
									onClick={() => handleSelection("Own with mortgage", 0.4)}
								>
									Own with mortgage
								</button>
							</div>
						</div>
					)}

					{/* Page 10 */}
					{currentPage === 10 && (
						<div className="form-page">
							<h2>What’s your name?</h2>
							<div className="options-grid">
								<div className="input-groups">
									<label htmlFor="first-name">First Name</label>
									<input
										type="text"
										id="first-name"
										placeholder="e.g Nathan"
										name="first"
									/>
								</div>
								<div className="input-groups">
									<label htmlFor="last-name">Last Name</label>
									<input
										type="text"
										id="last-name"
										placeholder="e.g Pascal"
										name="last"
									/>
								</div>
							</div>
							{currentPage !== 12 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={currentPage === 14}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{/* Page 11 */}
					{currentPage === 11 && (
						<div className="form-page">
							<h2>What’s your email address?</h2>
							<div className="input-groups">
								<label htmlFor="email">Email Address</label>
								<input
									type="text"
									id="email"
									placeholder="e.g John@example.com"
									name="mail"
								/>
							</div>
							{currentPage !== 12 && (
								<div className="form-navigation">
									<button onClick={handleNext} disabled={currentPage === 14}>
										Continue
									</button>
								</div>
							)}
						</div>
					)}

					{/* Page 12 */}
					{currentPage === 12 && (
						<div className="form-page form-sucess">
							<img src={m1} alt="check" width={50} />
							<h2>
								Approved: {total}% rate on your $
								{formData.loanAmount || "Not Set"} <br />
								loan!
							</h2>
							<p>
								Your ${formData.loanAmount || "Not Set"} loan is approved at
								a locked-in 4% rate. Secure your funds now—click “Apply” below
								to finalize and access financing immediately.
							</p>
							<button
								className="rate-form-button"
								onClick={() => (window.location.href = "/")}
							>
								Apply for loan
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Checkrate;
