import React, { useEffect } from "react";
import "../css/pricingTable.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleSlider from "./MobileTable";
import Premium from "./premium.png";
import pro from "./pro.png";
import check from "./3.png";
import pl from "./pl.png";
import bl from "./bl.png";
import ml from "./ml.png";
import cl from "./cl.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function PricingTable() {
	const settings = {
		centerMode: true,
		centerPadding: "20px", // Adds padding between cards
		draggable: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 3,
		slidesToScroll: 2,
		dots: true,
	};

	const { t } = useTranslation();
	// useEffect(() => {
	// 	AOS.init({
	// 		duration: 1000,
	// 	});
	// }, []);
	return (
		<div className="pTable">
			<div className="container">
				<div className="pricingtable priceWind" id="plans">
					<div className="pricecont" data-aos="fade-right">
						<h1>Our Loan Offerings</h1>
					</div>

					<div className="windowt">
						<Slider {...settings}>
							{/* Start Table */}
							<div>
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>Personal loans</h6>
										<img src={pl} alt="pl" />
										<div className="price-range">
											<span>Borrow $1,000 - $100,000 </span>
										</div>
									</div>
									<div className="pricig-body price_checks">
										<ul>
											<li>
												<img src={check} alt="check" width={"20px"} />
												43% lower rates than traditional banks.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Checking your rate is free and only takes 5 minutes.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Won’t affect your credit score.
											</li>
										</ul>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn" href="/loans">
											Apply for loan
										</a>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn-w" href="/check-rate">
											Check your rate
										</a>
									</div>
								</div>
							</div>
							{/* End Table */}
							{/* Start Table */}
							<div>
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>Business loans</h6>
										<img src={bl} alt="bl" />
										<div className="price-range">
											<span>Borrow $100,000 - $100M </span>
										</div>
									</div>
									<div className="pricig-body price_checks">
										<ul>
											<li>
												<img src={check} alt="check" width={"20px"} />
												38% lower rates than traditional banks.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Simple terms & competitive rates.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												No early repayment fees.
											</li>
										</ul>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn" href="/loans">
											Apply for loan
										</a>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn-w" href="/check-rate">
											Check your rate
										</a>
									</div>
								</div>
							</div>
							{/* End Table */}
							{/* Start Table */}
							<div>
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>Car loans</h6>
										<img src={cl} alt="cl" />
										<div className="price-range">
											<span>Access $5,000 - $500,000 </span>
										</div>
									</div>
									<div className="pricig-body price_checks">
										<ul>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Average yearly savings of $1,720.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Get your offer in minutes.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Won’t affect your credit score.
											</li>
										</ul>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn" href="/loans">
											Apply for loan
										</a>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn-w" href="/check-rate">
											Check your rate
										</a>
									</div>
								</div>
							</div>
							{/* End Table */}
							{/* Start Table */}
							<div>
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>Morgage</h6>
										<img src={ml} alt="ml" />
										<div className="price-range">
											<span>Access $10,000 - $4M </span>
										</div>
									</div>
									<div className="pricig-body price_checks">
										<ul>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Fast pre-approvals.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												Lower APR than traditional banks.
											</li>
											<li>
												<img src={check} alt="check" width={"20px"} />
												No impact to credit score.
											</li>
										</ul>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn" href="/loans">
											Apply for loan
										</a>
									</div>
									<div className="pricing-btn mt-35">
										<a className="table-btn-w" href="/check-rate">
											Check your rate
										</a>
									</div>
								</div>
							</div>
							{/* End Table */}
						</Slider>
					</div>
					<SimpleSlider />
				</div>
			</div>
		</div>
	);
}

export default PricingTable;
