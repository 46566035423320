import React, { useEffect } from "react";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "../css/usersPages.css";
import UserFund from "./UserFund";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Fund() {
	const { t } = useTranslation();
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	return (
		<div className="userbg">
			<UserHeader title="Payments" />
			<div className="user_app">
				<Sidenav />
				<UserFund />
			</div>
		</div>
	);
}

export default Fund;
