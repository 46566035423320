import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import close from "../../Sidebar/images/close.png";
import sus from "./sus.png";
import info from "./info.png";
import UserHeader from "../../Header/Header";
import Select from "react-select";
import { CgBitcoin, CgEthereum } from "react-icons/cg"; // Bitcoin, Ethereum
import { SiTether, SiBinance, SiDogecoin } from "react-icons/si"; // USDT, BNB, DOGE
import {
	FaBitcoin,
	FaEthereum,
	FaTether,
	FaCoins,
	FaDog,
} from "react-icons/fa";

const CryptoWithdraw = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [formE, setformE] = useState(false);
	const [token, settoken] = useState("");
	const [usersname, setusersname] = useState("");
	const [cryptoType, setCryptoType] = useState("BTC");
	const [walletAddress, setWalletAddress] = useState("");

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [usersbal, setusersbal] = useState("");

	const defaultAmount = parseFloat(searchParams.get("amount")) || "";
	const [amount, setamount] = useState(defaultAmount);
	const gasFee = amount ? amount * 0.1 : 0;
	const totalAmount = amount ? amount : 0;
	const [price, setPrice] = useState(null);

	const cryptoOptions = [
		{
			label: (
				<>
					<FaBitcoin style={{ color: "#2C14DD", width: "20px" }} /> Btc
				</>
			),
			value: "BTC",
		},
		{
			label: (
				<>
					<FaEthereum style={{ color: "#2C14DD", width: "20px" }} /> Eth
				</>
			),
			value: "ETH",
		},
		{
			label: (
				<>
					<SiTether style={{ color: "#2C14DD", width: "20px" }} /> Usdt
				</>
			),
			value: "USDT",
		},
		{
			label: (
				<>
					<SiBinance style={{ color: "#2C14DD", width: "20px" }} /> Bnb
				</>
			),
			value: "BNB",
		},
		{
			label: (
				<>
					<SiDogecoin style={{ color: "#2C14DD", width: "20px" }} /> Doge
				</>
			),
			value: "DOGE",
		},
	];

	const cryptoNames = {
		BTC: {
			name: "Bitcoin",
			code: "Btc",
			icon: <FaBitcoin style={{ color: "#2C14DD" }} />,
		},
		ETH: {
			name: "Ethereum",
			code: "Eth",
			icon: <FaEthereum style={{ color: "#2C14DD" }} />,
		},
		USDT: {
			name: "Tether",
			code: "Usdt",
			icon: <SiTether style={{ color: "#2C14DD" }} />,
		},
		BNB: {
			name: "Binance Coin",
			code: "Bnb",
			icon: <SiBinance style={{ color: "#2C14DD" }} />,
		},
		DOGE: {
			name: "Dogecoin",
			code: "Doge",
			icon: <SiDogecoin style={{ color: "#2C14DD" }} />,
		},
	};

	const handleChange = (selectedOption) => {
		setCryptoType(selectedOption.value);
	};

	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			const userData = JSON.parse(localStorage.getItem("SeamR_user"));
			settoken(userData.token);
			setusersname(userData.user.name);
		}
	}, []);

	const handleMaxClick = () => {
		setamount(usersbal?.total || "");
	};

	useEffect(() => {
		const storedUsers = localStorage.getItem("SeamR_users");
		if (storedUsers) setusersbal(JSON.parse(storedUsers));
	}, []);

	const isFormValid =
		walletAddress.trim() !== "" && // Check if wallet address is provided
		amount > 0; // Ensure that the amount is greater than 0

	const onSubmit = (e) => {
		e.preventDefault();
		setformE(true);

		let result = {
			name: "withdraw",
			slug: email + " " + cryptoType,
			description: "Loan withdrawal",
			price: totalAmount,
			gasFee: gasFee,
			username: usersname,
			usertoken: token,
			cryptoType: cryptoType,
			walletAddress: walletAddress,
			accept: 0,
		};

		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};

		axios
			.post(
				"https://seamridge.com/backend/public/api/products",
				result,
				axiosConfig
			)
			.then((res) => {})
			.catch((err) => {});
	};
	useEffect(() => {
		const fetchCryptoPrice = async () => {
			try {
				const response = await fetch(
					`https://min-api.cryptocompare.com/data/price?fsym=${cryptoType}&tsyms=USD`
				);
				const data = await response.json();
				console.log(data);

				setPrice(data.USD || "N/A");
			} catch (error) {
				console.error("Error fetching price:", error);
				setPrice("Error");
			}
		};

		if (cryptoType) {
			fetchCryptoPrice();
		}
	}, [cryptoType]); // Re-run whenever `cryptoType` changes
	

	return (
		<>
			<UserHeader title="Digital currencies (Crypto)" fke={true} />
			<div className="crypto-withdraw-container">
				<h2>Withdraw via Crypto</h2>
				<p>Select a cryptocurrency and enter your wallet address.</p>

				<form onSubmit={onSubmit}>
					<label>Choose Cryptocurrency:</label>

					<Select
						className="crypto-dropdown"
						options={cryptoOptions}
						value={cryptoOptions.find((option) => option.value === cryptoType)}
						onChange={handleChange}
						getOptionLabel={(e) => e.label}
					/>
					<div className="space-btm"></div>

					<label>Wallet Address:</label>
					<input
						type="text"
						value={walletAddress}
						className="crypin"
						onChange={(e) => setWalletAddress(e.target.value)}
						placeholder="Enter your wallet address"
					/>

					<div className=" inputs-cont">
						<span className="currency">$</span>
						<input
							type="number"
							value={amount}
							onChange={(e) =>
								setamount(
									e.target.value === "" ? "" : parseFloat(e.target.value) || ""
								)
							}
						/>
						<div className="max-bal">
							<button
								className="max-button"
								type="button"
								onClick={handleMaxClick}
							>
								Max
							</button>
							<p>Balance: {usersbal ? usersbal.total.toLocaleString() : ""} </p>
						</div>
					</div>
					{/* Show the full name and icon of the selected cryptocurrency */}
					<div className="selected-crypto">
						<div className="select-c">
							{cryptoNames[cryptoType] && (
								<>
									{cryptoNames[cryptoType].icon}{" "}
									<span>{cryptoNames[cryptoType].name}</span>
								</>
							)}
							<p>You will get</p>
						</div>
						<h4>
							{" "}
							{(amount / price).toFixed(6)}{" "}
							<span>{cryptoNames[cryptoType].name}</span>
						</h4>
					</div>
					<div className="gas-f">
						<p>
							<span style={{ color: "#6E6E6E" }}>Gas fee: </span>{" "}
							<span style={{ color: "#010101" }}>
								{(gasFee / price).toFixed(6)}{" "}
								<span>{cryptoNames[cryptoType].name}</span>
							</span>
							<br />
							<p>= ${amount ? gasFee.toFixed(2) : "0.00"}</p>
						</p>
					</div>
					<div className="amount-details">
						<br />
						<p>
							<strong>
								{cryptoNames[cryptoType] && <>{cryptoNames[cryptoType].name}</>}{" "}
								= ${price ? price.toLocaleString() : "0.00"}
							</strong>
						</p>
					</div>
					<div className="cryptext">
						<img src={info} alt="info" />
						<p>
							You will make a one-time gas fee payment of $
							{amount ? gasFee.toFixed(2) : "0.00"} to process your withdrawal
							to your bitcoin wallet.
						</p>
					</div>
					<button
						className={`continue-btn ${isFormValid ? "active" : ""}`}
						disabled={!isFormValid}
					>
						Withdraw
					</button>
				</form>
				{/* show */}
				<Modal show={formE} onHide={() => setformE(false)} centered>
					<div className="mo_head">
						<Modal.Title></Modal.Title>
						<span onClick={() => setformE(false)}>
							<img src={close} alt="close" />
						</span>
					</div>
					<div className="mo_body">
						<img src={sus} width={"80px"} alt="check" />
						<h3>Withdrawal request successful!</h3>
						<p>
							You have successfully initiated a withdrawal request. Requests are
							usually processed within 1 hour.
						</p>
					</div>
					<div className="mo_foot">
						<button
							className="mo_btn"
							onClick={() => {
								setformE(false);
								navigate("/app/withdraw"); // Redirect to /app/withdraw
							}}
						>
							Okay
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default CryptoWithdraw;
