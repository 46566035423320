import React from "react";
import "./dash.css";
import help from "./images/help.png";
import panel from "./images/panel.png";
import wif from "./images/wif.png";
import master from "./images/master.png";
import refer from "./images/refer.png";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DashSlide from "./DashSlide";
function Dash({ user, total, limit }) {
	const { t } = useTranslation();
	//DATE CREATION
	const createdDate = user.created_at;
	let dateIn = moment(createdDate, "YYYY/MM/DD");
	const userdate = parseInt(dateIn.format("Y"));
	const today = new Date();
	const today_year = today.getFullYear();
	const today_month = today.getMonth() + 1;
	const month = dateIn.format("M");
	const panelyear = userdate + 4;
	const year =
		today_year == panelyear && today_month == month ? panelyear + 4 : panelyear;
	//DATE CREATION ENDS
	return (
		<div className="user_dash">
			<span className="secound_dash">
				<div className="sdash_cont">
					<p>Balance available</p>
					<h3>$ {total.toLocaleString()}</h3>
					<div className="dsh_body">
						<div className="dsh_b">
							<p>
								Credit limit <br /> <span>${limit.toLocaleString()}</span>{" "}
							</p>
						</div>
						<div className="dsh_b">
							<p>
								VALID THRU <br />{" "}
								<span>
									<span>{`${month}/${year}`}</span>
								</span>{" "}
							</p>
						</div>
					</div>
				</div>
				<div className="ldashcont">
					<span className="dash_port">
						<img src={panel} alt="panel" className="ldash_panel" />
						<img src={wif} alt="wif" className="ldash_wif" />
					</span>
				</div>

				<div className="dash_btm">
					<a className="dsh_btn" href="/app/withdraw">
						Apply for loan
					</a>
					<span className="dash_btm_img">
						<img src={master} alt="master" className="ldash_master" />
					</span>
				</div>
			</span>
			<span className="first_dash">
				<DashSlide />
			</span>

			<span className="thirld_dash">
				<Link className="menu-item" exact="true" to="/app">
					<img src={refer} alt="dash" />
					Refer & get cash reward
				</Link>

				<Link className="menu-item" exact="true" to="/app">
					<img src={help} alt="dash" />
					Get Help
				</Link>
			</span>
		</div>
	);
}

export default Dash;
