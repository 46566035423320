import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import check from "./3.png";
export default function SimpleSlider() {
	const { t } = useTranslation();
	const settings = {
		centerMode: false,
		draggable: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
	};
	return (
		<div className="container mobile">
			<Slider {...settings}>
				{/* Start Table */}
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>Personal loans</h6>
							<div className="price-range">
								<span>Borrow $1,000 - $100,000 </span>
							</div>
						</div>
						<div className="pricig-body price_checks">
							<ul>
								<li>
									<img src={check} alt="check" width={"20px"} />
									35% lower rates than traditional banks.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Checking your rate is free and only takes 5 minutes.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Won’t affect your credit score.
								</li>
							</ul>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn" href="/loans">
								Apply for loan
							</a>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn-w" href="/check-rate">
								Check your rate
							</a>
						</div>
					</div>
				</div>
				{/* End Table */}
				{/* Start Table */}
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>Business loans</h6>
							<div className="price-range">
								<span>Borrow $100,000 - $100M </span>
							</div>
						</div>
						<div className="pricig-body price_checks">
							<ul>
								<li>
									<img src={check} alt="check" width={"20px"} />
									35% lower rates than traditional banks.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Checking your rate is free and only takes 5 minutes.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Won’t affect your credit score.
								</li>
							</ul>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn" href="/loans">
								Apply for loan
							</a>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn-w" href="/check-rate">
								Check your rate
							</a>
						</div>
					</div>
				</div>
				{/* End Table */}
				{/* Start Table */}
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>Car loans</h6>
							<div className="price-range">
								<span>Access $10,000 - $500,000 </span>
							</div>
						</div>
						<div className="pricig-body price_checks">
							<ul>
								<li>
									<img src={check} alt="check" width={"20px"} />
									35% lower rates than traditional banks.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Checking your rate is free and only takes 5 minutes.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Won’t affect your credit score.
								</li>
							</ul>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn" href="/loans">
								Apply for loan
							</a>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn-w" href="/check-rate">
								Check your rate
							</a>
						</div>
					</div>
				</div>
				{/* End Table */}
				{/* Start Table */}
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>Morgage loans</h6>
							<div className="price-range">
								<span>Access $10,000 - $500,000 </span>
							</div>
						</div>
						<div className="pricig-body price_checks">
							<ul>
								<li>
									<img src={check} alt="check" width={"20px"} />
									35% lower rates than traditional banks.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Checking your rate is free and only takes 5 minutes.
								</li>
								<li>
									<img src={check} alt="check" width={"20px"} />
									Won’t affect your credit score.
								</li>
							</ul>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn" href="/loans">
								Apply for loan
							</a>
						</div>
						<div className="pricing-btn mt-35">
							<a className="table-btn-w" href="/check-rate">
								Check your rate
							</a>
						</div>
					</div>
				</div>
				{/* End Table */}
			</Slider>
		</div>
	);
}
