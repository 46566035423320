import React, { useState } from "react";
import "../css/counterup.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
const questions = [
	{
		id: 1,
		question: "What does Seamridge do?",
		answer:
			"Seamridge is a multi-national loan platform providing a range of financing solutions, including personal, business, mortgage, and car loans. We're committed to offering the lowest possible interest rates and APRs to our over 2 million customers in 5 countries. A key difference with Seamridge is that applying for a loan won't impact your credit score.",
	},
	{
		id: 2,
		question: "What is the difference between a business and a personal loan?",
		answer:
			"Business loans are used for business purposes and are evaluated based on your business's financial health. Personal loans are for personal expenses and are based on your individual creditworthiness and income. They also often differ in loan amounts, interest rates, and repayment terms.",
	},
	{
		id: 3,
		question: "What is a car loan?",
		answer:
			"A car loan is a secured loan specifically for buying a vehicle. The vehicle itself acts as collateral, meaning Seamridge can repossess it if you fail to make payments. You repay the loan in installments over a set period, with a favorable interest.",
	},
	{
		id: 4,
		question: "What is a mortgage?",
		answer:
			"At Seamridge, we offer mortgages to help you achieve your homeownership dreams. A mortgage is a loan secured by the property you're buying, allowing you to spread the cost over a manageable period.",
	},
	{
		id: 5,
		question: "Will my credit score be affected?",
		answer:
			"At Seamridge, we believe in providing accessible financing. That's why we've developed an alternative evaluation method that doesn't rely on traditional credit scores. Applying for a loan with us will not have any impact on your credit report.",
	},
	{
		id: 6,
		question: "How long will it take to be credited?",
		answer: "Upon approval, funds are usually available within 24 hours.",
	},
	{
		id: 7,
		question: "Is a fee attached with early payment of loan?",
		answer:
			"No, there are no fees for early repayment of your loan with Seamridge. You're welcome to pay off your loan faster without incurring any penalties.",
	},
];

const CounterUpOne = () => {
	const [activeIndex, setActiveIndex] = useState(null);

	const handleToggle = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div className="section section-padding bg-color-dark">
			<div className="container" data-aos="fade-right">
				<h3>Frequently Asked Questions</h3>
				<div className="faq">
					{questions.map((item, index) => (
						<div key={item.id} className="faq-item">
							<div className="question" onClick={() => handleToggle(index)}>
								<h4>{item.question}</h4>
								<button>
									{activeIndex === index ? (
										<MdKeyboardArrowUp />
									) : (
										<MdKeyboardArrowDown />
									)}
								</button>
							</div>
							<div
								className={`answer ${activeIndex === index ? "active" : ""}`}
							>
								{item.answer}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CounterUpOne;
