import React from "react";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "../css/usersPages.css";
import UserWithdraw from "./UserWithdraw";
import { useTranslation } from "react-i18next";
function Withdraw() {
	const { t } = useTranslation();
	return (
		<div className="userbg">
			<UserHeader title="Withdrawals" />
			<div className="user_app">
				<Sidenav />
				<UserWithdraw />
			</div>
		</div>
	);
}

export default Withdraw;
