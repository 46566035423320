import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Adminnav from "../Sidebar/Sidenav";
import ItemsR from "./itemsR";
import "../css/admin.css";
function FundsR() {
	const [users, setusers] = useState("");
	const history = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem("SeamRAdmin_Demz")) {
			history("/admin");
		}
	}, []);
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				const sorted = result.sort((a, b) => {
					return b.id - a.id;
				});
				const fundsrequest = sorted.filter(
					(item) =>
						(item.name == "Loan" && item.created_at && item.accept == 0) 
				);
				setusers(fundsrequest);
			} catch (err) {}
		}

		fetchMyAPI();
	}, []);
	return (
		<div className="admincont">
			<Adminnav />
			<div className="maincont">
				<h1>Users Loan Requests</h1>
				<div className="UsersR">
					{users &&
						users.map((item) => {
							return (
								<ItemsR
									key={item.id}
									plan={item.slug}
									user={item.username}
									token={item.usertoken}
									amount={item.price}
									id={item.id}
									type={item.name}
								/>
							);
						})}
				</div>
			</div>
		</div>
	);
}

export default FundsR;
