import React, { useState } from "react";
import CarLoanForm from "./CarForm";
import MorgageForm from "./MorgageForm";
import Nav from "../../Nav/Nav";
import BusinessForm from "./BusinessForm";
import PersonalForm from "./PersonalForm";
import o1 from "./o1.png";
import o2 from "./o2.png";
import o3 from "./o3.png";

const LoanWizard = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [formData, setFormData] = useState({ loanType: "" });

	const handleLoanSelection = (loanType) => {
		setFormData({ ...formData, loanType });
		setCurrentPage(2); // Move to the selected loan's component
	};

	const renderLoanComponent = () => {
		switch (formData.loanType) {
			case "Car Loan":
				return <CarLoanForm />;
			case "Personal Loan":
				return <PersonalForm />;
			case "Business Loan":
				return <BusinessForm />;
			case "Mortgage":
				return <MorgageForm />;
			default:
				return null;
		}
	};

	return (
		<div className="cbody">
			<Nav />
			{currentPage === 1 ? (
				<div className="loan-form-container">
					<div className="loan-form">
						<div className="progress-bar">
							<div
								className="progress"
								style={{ width: `${(currentPage / 14) * 100}%` }}
							/>
						</div>

						<div className="form-page">
							<h2>Select your loan offering</h2>
							<div className="options-grid">
								<button
									className="option-button"
									onClick={() => handleLoanSelection("Personal Loan")}
								>
									Personal Loan
								</button>
								<button
									className="option-button"
									onClick={() => handleLoanSelection("Business Loan")}
								>
									Business Loan
								</button>
								<button
									className="option-button"
									onClick={() => handleLoanSelection("Mortgage")}
								>
									Mortgage
								</button>
								<button
									className="option-button"
									onClick={() => handleLoanSelection("Car Loan")}
								>
									Car Loan
								</button>
							</div>
						</div>
					</div>
					<div className="loan-info">
						<div className="loan-info-content">
							<ul>
								<h4>Access our loan offers in minutes</h4>
								<li>
									<span>
										<img src={o1} alt="check" width={30} />
									</span>
									<span>
										<b>No impact to your credit score</b>
										<p>
											Check out & compare loan offers without hurting your
											credit score.
										</p>
									</span>
								</li>
								<li>
									<span>
										<img src={o2} alt="check" width={30} />
									</span>
									<span>
										<b>40M+ customer loan applications yearly</b>
										<p>
											Join the millions of customers who trust us for their
											financial needs.
										</p>
									</span>
								</li>
								<li>
									<span>
										<img src={o3} alt="check" width={30} />
									</span>
									<span>
										<b>$1B+ financial products disbursed yearly</b>
										<p>
											Get quick access to the right financial product from any
											of our 100+ partners.
										</p>
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			) : (
				// Render the selected loan component when currentPage is 2
				renderLoanComponent()
			)}
		</div>
	);
};

export default LoanWizard;
