import React, { useEffect } from "react";
import gif from "./banerG.png";
import "aos/dist/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/about.css";
import { useTranslation } from "react-i18next";
function AboutHero() {
	const { t, i18n } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<>
			<section className="appie-hero-area">
				<div className="container">
					<div className="container_bg cont-n">
						<div className="row align-items-center" data-aos="fade-left">
							<div className="col-lg-6 about-h">
								<div className="appie-hero-content">
									<p className="abfontstyle">MISSION, VALUES + GOALS</p>
									<h1 className="appie-title">
										Simplifying <br />
										access to loans
									</h1>
									<p className="pbody">
										We’re committed to providing fast, fair, and flexible loan
										solutions that put your financial goals first.
									</p>
									<ul>
										<li>
											<a href="/loans">Apply for loan</a>
										</li>
									</ul>

									<br />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="appie-hero-thumb">
									<div
										className="thumb wow animated fadeInUp"
										data-wow-duration="2000ms"
										data-wow-delay="200ms"
										id="HeroVid"
									>
										<img
											src={gif}
											alt="gif"
											className="gifbanner about_banner"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default AboutHero;
