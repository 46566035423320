import React, { useEffect } from "react";
import "../css/about.css";
import featuresOne from "./features1.png";
import featuresTwo from "./features2.png";
import o1 from "./1.png";
import o2 from "./2.png";
import o3 from "./3.png";
import o4 from "./4.png";
import check from "./check.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function AboutDetails() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="">
			<main className="main">
				<div className="why-sec abwh">
					<div className="abtxt">
						<h3>What sets us apart</h3>
						<span>
							With instant aspanprovals and zero paperwork, we’re redefining how
							loans should work in the digital age.
						</span>
					</div>
					{/* Why Choose Seamridge Section */}
					<section className="why-choose abwhy">
						<div className="image-container">
							<img src={featuresOne} alt="Why Choose Seamridge" />
						</div>
						<div className="text-content">
							<ul>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>
									<span>
										<b>Fast, digital-first approval processes.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>No hidden fees.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Ethical lending practices.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Flexible repayment terms.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Competitive interest rates</b>
									</span>
								</li>

								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>AI-driven risk assessments.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>Flexible repayment terms.</b>
									</span>
								</li>
								<li>
									<span>
										<img src={check} alt="check" width={30} />
									</span>{" "}
									<span>
										<b>A24/7 customer support.</b>
									</span>
								</li>
							</ul>
						</div>
					</section>
				</div>
				<div className="loan-sec">
					<h3>Apply Online → Instant Approval → Funds in Your Account</h3>

					{/* Loan Process Section */}
					<section className="loan-process ab-lpro">
						<div className="text-content">
							<ul>
								<li>
									<span>
										{" "}
										<img src={o1} alt="number" width={30} />
									</span>{" "}
									<span>
										<b> Check your rate</b>
										<p>
											Get pre-qualified in just a few minutes. Checking your
											rate is free and won't impact your credit score.
										</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o2} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Select a loan offer</b>
										<p>
											Sign up, apply for a loan, and select a loan offer that
											suits your needs.
										</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o3} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Finalize your loan offer</b>
										<p>Secure your funding by accepting the loan offer.</p>
									</span>
								</li>
								<li>
									<span>
										{" "}
										<img src={o4} alt="number" width={30} />
									</span>{" "}
									<span>
										<b>Get credited</b>
										<p>Receive your money into your account within 24 hours.</p>
									</span>
								</li>
							</ul>
						</div>
						<div className="image-container">
							<img src={featuresTwo} alt="Easy Loan Process" />
						</div>
					</section>
				</div>
			</main>
		</div>
	);
}

export default AboutDetails;
